@import "../../../../assets/stylesheets/var";

.tooltip-heatmap {
    span {
      color: $primary-color-2;
      font-family: $quicksand;
    letter-spacing: 0.672px;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 130%;
    }
  }
  .tooltip-title{
    font-family: $quicksand;
    color:$background-1;
    letter-spacing: 0.672px;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 130%;
}

.heatmap-padding-top{
  padding-top: 10px;
}

.heatmap-padding-bottom{
  padding-bottom: 5px;
}

.heatmap-box-cursor{
  position: relative;
  height: 100%;
  cursor: pointer;
}

#single-heatmap-cell > div:first-child {
    padding-top: 6px !important;
    height: 100%;
  }

  .profile-header-text{
  font-family: $quicksand;
  color: $primary-text;
  letter-spacing: 0.672px;
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 130%;
  }

  .profile-text{
  font-family: $quicksand;
  color: $primary-text;
  letter-spacing: 0.672px;
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 130%;
  }

.info-icon {
  height: 20px;
  width: 20px;
  position: absolute;
  right: 5px;
  bottom: 5px;
  cursor: pointer;
}
.heatmap-label-text {
  margin: 0 auto;
  text-align: center
}

.heatmap-persona-profile {
  margin-top: 120px;
  .MuiPaper-root.MuiDialog-paper {
    padding: 20px;
    border-radius: 20px;
  }
  .close-icon {
    cursor: pointer;
    width: 24px;
    height: 24px;
    margin-top: 10px;
    margin-right: 10px;
  }
}
