@import "../../../../../../assets/stylesheets/var";


.images-text{
    font-family: $quicksand;
    font-weight: 600;
    font-size: 16px;
    line-height: 20.7px;
    color: $primary-color;
    padding-bottom: 20px;
    padding-right: 7px;
    padding-top: 20px
}

.expand-collapse-icons{
  width: 24px;
  height: 24px;
}

.margins-left{
  padding-left: 15px;
  padding-right: 15px;
}

.scroll-height{
  max-height: 700px; 
  overflow-y: scroll; 
  padding-right: 15px; 
  .nav-container{
    position: relative;
    width: 100%;
  }
  .left-button{
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    border: none;
    color: white;
    z-index: 1;
    cursor: pointer;
    opacity: 2; 
    transition: opacity 0.3s;
    .back-image{
      opacity: 1;
    }
  }
  .right-button{
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    border: none;
    color: white;
    z-index: 1;
    cursor: pointer;
    opacity: 2; 
    transition: opacity 0.3s; 
    .back-image{
      opacity: 1;
    }
  }
  .scroll-container{
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    width: 100%;
    padding-bottom: 15px; 
  }
}

.buttons-top{
  margin-top: 20px;
}

.generated-images-background {
    background-color: $background;
  }

  // Edit design
  .generated-image-edit {
    width: 250px;
    height: 250px;
    margin-right: 15px;
    margin-top: 5%;
    .heading {
      padding-left: 4%;
      padding-top: 4%;
      font-family: $quicksand;
      font-weight: 700;
      font-size: 24px;
      line-height: 31.2px;
      letter-spacing: 4.2%;
      color: $background;
    }
    .title {
      padding-left: 4%;
      padding-top: 2%;
      font-family: $quicksand;
      font-weight: 700;
      font-size: 20px;
      line-height: 26px;
      letter-spacing: 4.2%;
      color: $background;
    }
    .subtitle {
      padding-left: 4%;
      padding-top: 4%;
      font-family: $quicksand;
      font-weight: 400;
      font-size: 16px;
      line-height: 20.7px;
      letter-spacing: 4.2%;
      color: $background;
    }
    .action-button-div {
      background-color: #ff8469;
      height: 47px;
      border-radius: 20px;
      margin-left: 9%;
      margin-right: 9%;
      margin-top: auto;
      margin-bottom: 4%;
      .action-button {
        font-family: $quicksand;
        font-weight: 700;
        font-size: 16px;
        line-height: 20.7px;
        letter-spacing: 4.2%;
        color: $background;
      }
    }
    .content {
      padding-left: 4%;
      padding-top: 2%;
      padding-right: 10%;
      padding-bottom: 10%;
      font-family: $quicksand;
      font-weight: 400;
      font-size: 16px;
      line-height: 20.7px;
      letter-spacing: 4.2%;
      color: $background;
    }
  }

  .navigation-buttons-new {
    .navigation-icons {
      border: none;
      cursor: pointer;
      width: 60px;
      height: 60px;
    }
  }

.mainFeedText{
  font-family: $quicksand;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: $primary-text;
  padding-bottom: "4px"
}

.image-div{
  cursor: pointer;
  margin-right: 20px;
  padding-top: 20px;
  .img{
    width: 24px;
    height: 24px
  }
}

.infos-margins{
  padding-left: 15px;
  padding-top: 15px
}

.dimensionsText{
  font-family: $quicksand;
  font-weight: 500;
  font-size: 14px;
  line-height: 17.5px;
  letter-spacing: 1.5%;
  color: $secondary-text;
}