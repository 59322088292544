@import "../../assets/stylesheets/var";

.manage-api-section {
  // background-color: $background-1;
  margin-bottom: 140px;
  .heading {
    font-family: $quicksand;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 31.2px */
    letter-spacing: 1.008px;
    color: $primary-text;
  }
  
  .component-rendered {
    margin-top: 1.25rem;
  }
  .renderedActiveComponent {
    position: absolute;
    right: 25%;
    top: 11%;
  }
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(
      0,
      0,
      0,
      0.5
    ); /* Semi-transparent black background */
  }
}

.back-button {
  border: none;
  background: none;
  padding: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.back-icon {
  height: 24px;
  width: 24px;
}

.back-text {
  margin-left: 5px;
}

.left-column,
.right-column {
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  min-height: 40vh;
  padding: 0px 10px;
  overflow-y: auto;
  scrollbar-width: thin;
}


.SelecteService {
  flex-grow: 1;
}

.test-and-save-buttons {
  margin-top: auto;
  gap: 20px;
  display: flex;
  justify-content: center;
}



.divider {
  background-color: $gray-color;
  width: 8px;
}

.markdown-container {
  position: relative;
  flex: 1;
  padding-top: 20px;

    a {
      text-decoration: underline;
      color: $blue;
      display: inline-block;
      font-family: $quicksand;
      font-size: 1rem;
      letter-spacing: 0%;
    }

    ol {
      margin: 5px 0px 0px 0px;
      padding-left: 20px;
      list-style-type: decimal;
    }
    ul {
      margin: 5px 0px 0px 0px;
      padding-left: 20px;
      list-style-type: disc;
    }

    ol li {
      list-style-type: upper-roman;
      margin-bottom: 5px;
      font-family: $quicksand;
      font-size: 1rem;
      line-height: 1;
    }

    ul li {
      list-style-type: disc;
      margin-bottom: 5px;
      font-family: $quicksand;
      font-size: 1rem;
      line-height: 1;
      }
    .loader-box {
      position: absolute;
      // top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      margin-top: 15rem;
    }
}

.markdown-container h1,
.markdown-container h2,
.markdown-container h3,
.markdown-container h4,
.markdown-container h5,
.markdown-container h6 {
  font-size: 1.5rem;
  line-height: 1.3;
  padding: 10px 0px 0px 0px;
}

.markdown-container p {
  font-size: 1rem;
  line-height: 1.3;
}

.markdown-container strong {
  font-size: 1rem;
  line-height: 1.3;
  font-weight: 600;
}
