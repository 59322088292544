@import "../../../../../assets/stylesheets/var";

.language-dropdown {
  .default-icon {
    cursor: pointer;
  }
  .language-dropdown-items {
    position: absolute;
    // top: 5rem;
    width: 15.6rem;
    background-color: $background-1;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    // border: 1px solid $tert-text;
    border-radius: 10px;
    z-index: 2;
    .language-item {
      padding: 0.625rem 0.9375rem;
      cursor: pointer;
      transition: background-color 0.3s ease;
      &:hover {
        background-color: $primary-color-4;
        color: $primary-color;
        border-radius: 10px;
      }

      img {
        width: 1.5rem;
        height: 1.5rem;
      }

      p {
        font-size: 1rem;
        color: $fourth-text;
        margin: 0;
      }
    }
  }
}
