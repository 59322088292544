@import "../../../../assets/stylesheets/var";

.heatmap {
  padding: 10px;
  position: relative;

  .heatmap-comp {
    height: 200px;
    // width: 164vw;
  }
  .navigation-buttons {
    margin: auto;
    button {
      margin-left: 5px;
      background-color: $background-1;
      padding: 2px 5px;
      border: none;
      border-radius: 50%;
    }
  }
  .year-label {
    font-size: 1rem;
    color: $primary-text;

    padding: 2px 5px;
  }

  .heatmap-labels {
    // width: 100%;
    overflow: scroll;

    .month-labels {
      display: flex;
      justify-content: space-around;
      width: 1235px;

      button {
        font-size: 0.8rem;
        font-weight: 600;
        color: $primary-text;
        cursor: pointer;
        background: none;
        border: none;
        background-color: $tert-text;
        padding: 6px;
        border-radius: 10px;
      }
      .button-color{
        background-color: $primary-color-4;
      }
    }

    .tooltip-heatmap {
      span {
        color: $primary-color-2;
      }
    }
  }
}
