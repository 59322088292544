@import "../../../../../assets/stylesheets/var";

.privacy-subheadings{
    font-weight: 600;
    font-size: 16px;
    line-height: 20.8px;
    letter-spacing: 4.2%;
    font-family: $quicksand;
    color: $secondary-text;
}
.new-heading{
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 1%;
    font-family: $quicksand;
    color: $secondary-text;
}
.sub-heading{
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 1%;
    font-family: $quicksand;
    color: $secondary-text;
}
.fingerprint-subheading{
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 5%;
    font-family: $quicksand;
    color: $secondary-text;
}
.site-tracking{
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 5%;
    font-family: $quicksand;
    color: $secondary-text;
}