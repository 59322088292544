@import "../../../../../../assets/stylesheets/var";

.content-body {
  box-shadow: 0px 4px 10px 0px #0000001a;
  background: $background;
  padding: 12px;
  border-radius: 10px;
  min-height: 350px;
  h3 {
    font-size: 17px;
    font-weight: 700;
    margin-bottom: 1rem;
  }
  position: relative;
}

.loader {
  .loader-child {
    margin-top: 125px;
  }
}

.no-data {
  p {
    font-size: 1rem;
    color: $secondary-text;
    margin-top: 125px;
    font-weight: 600;
  }
}

.data {
  .content {
    font-size: 1rem;
    font-family: $quicksand;
    font-weight: 500;
    padding: 10px 0px;
    margin-left: 10px;
    color: $primary-text;
  }
  .pagination-container {
    display: flex;
    flex-grow: 1;
    width: 100%;
    justify-content: flex-end;
    margin-top: 20px;
  }
}
