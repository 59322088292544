@import "../../../../../assets/stylesheets/var";

.bargraph-body {
  position: relative;
  padding: 10px;
}

/* Custom scrollbar for WebKit browsers */
.scrollable-container::-webkit-scrollbar {
  height: 10px; /* Decrease height for horizontal scrollbar */
}

.scrollable-container::-webkit-scrollbar-track {
  background: $background-1; /* Track background color */
  border-radius: 10px;
}

.scrollable-container::-webkit-scrollbar-thumb {
  background: $fourth-text; /* Scrollbar thumb color */
  border-radius: 10px; /* Rounded corners for the thumb */
}

.scrollable-container::-webkit-scrollbar-thumb:hover {
  background: $dark-gray; /* Darker color when hovering */
}


