@import "../../assets/stylesheets/var";

.authorized-header.settings-style {
  // position: fixed;
  position: relative;
  width: 100%;
  z-index: 9;
}

.settings {
  // background-color: $background-1;

  .settings-body {
    width: 70%;
    margin: 3% 0 0 0;
    .title {
      color: $primary-text;
      font-family: $quicksand;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 130%; /* 31.2px */
      letter-spacing: 1.008px;
      position: fixed;
    }
    .setting-icon {
      height: 24px;
      width: 24px;
      margin-right: 5px;
    }
    .settings-grid {
      margin: 3rem 0 0 0;
      min-width: 1050px;
      display: grid;
      // justify-content: end;
      grid-template-columns: 30% 69%;
      // grid-template-columns: 29% 69%;
      gap: 20px;

      .setting-tile {
        // height: 60px;
        display: flex;
        align-items: center;
        padding: 30px 20px;
        font-size: 16px;
        font-family: $quicksand;
        color: $secondary-text;
        line-height: 130%;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .setting-tile:hover {
        background-color: $primary-color-4;
      }

      .setting-tile.selected {
        background-color: $primary-color-3;
        color: $primary-text;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .left-panel,
      .right-panel {
        height: inherit;
        overflow-y: scroll;
        background-color: $background;
        // margin-bottom: 72px;
        // width: '200px';
      }

      .left-panel {
        // position: absolute;
        // width: 20%;
      }

      /* For tablets */
      // @media (max-width: 1024px) and (min-width: 768px) {
      //   .left-panel{
      //     left: 30px;
      //     width: 30%;
      //   }
      //   .right-panel {
      //     width: 1000px
      //   }
      //   .title {
      //     color: $primary-text;
      //     font-family: $quicksand;
      //     font-size: 1.5rem;
      //     font-weight: 700;
      //     line-height: 130%; /* 31.2px */
      //     letter-spacing: 1.008px;
      //     position: inherit;
      //   }
      //   .authorized-header.settings-style {
      //     // position: fixed;
      //     width: max-content;
      //     // z-index: 9;
      //     // overflow: auto;
      //   }
      // }

      .right-panel {
        // min-height: 60vh;
        // height: max-content;
        padding: 15px;
        width: 100%;
      }
    }
  }

  .settings-title {
    color: $primary-text;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 130%;
    letter-spacing: 1.008px;
  }
  .settings-subtitle {
    color: $primary-text;
    font-weight: 700;
    line-height: 130%; /* 20.8px */
    letter-spacing: 0.672px;
  }

  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
  .MuiAccordionSummary-content {
    font-family: $quicksand;
    color: $primary-text;
    font-weight: 700;
    line-height: 130%; /* 20.8px */
    letter-spacing: 0.672px;
    margin: 0 !important;
  }
  .MuiButtonBase-root.MuiAccordionSummary-root {
    height: 48px !important;
    min-height: 48px !important;
  }
  .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded {
    height: 48px !important;
    min-height: 48px !important;
  }
}

.swiper-container {
  padding: 10px 0 !important;
  overflow-x: hidden !important;
  overflow-y: visible !important;
}
