@import "../../../../assets/stylesheets/var";

.successPage{
    padding: 4% 0;

    background-color: $background-1;
    .content{
        width: 50vw;
        padding: 40px;

        .header{
            color: $primary-text;
            font-family: $quicksand;
            font-size: 24px;
            font-weight: 700;
            line-height: 130%; /* 31.2px */
            letter-spacing: 1.008px;
            margin-bottom: 10px;
        }

        .body{
            margin:30px 0 50px 0;
            color: $secondary-text;
            font-family: $quicksand;
            font-size: 16px;
            font-weight: 700;
            line-height: 130%; /* 20.8px */
            letter-spacing: 0.672px;

            .content-1{
                margin-bottom: 20px;
            }
        }
    }
}