/* DeleteComponent.scss */

.delete-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .delete-popup-box {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    padding: 20px;
  }
  
  .delete-popup-content {
    text-align: center;
  }
  
  .delete-popup-buttons {
    margin-top: 20px;
  }
  
  
  .delete-button {
    margin-left: 10px;
  }
  

  