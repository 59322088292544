@import "../../../../../assets/stylesheets/var";

.world-map-graph {
  .zoom-in,
  .zoom-out {
    background-color: $background-1;
    border: 1px solid grey;
    border-radius: 5px;
    padding: 5px;
    width: 30px;
    margin-bottom: 5px;
  }

  .tooltip {
    padding: 0 3px 5px 3px !important;
    width: 10vw !important;

    .tooltip-compare-data,
    .tooltip-data {
      font-family: $quicksand;
      font-weight: 700;
    }

    .tooltip-compare-data {
      color: $secondary-color-shade-2;
      padding: 10px 0;
    }
    .tooltip-data {
      color: $primary-color-3;
      padding: 5px 0 1px 0;
    }
  }

  .map-options {
    position: relative;
    top: 40px;
    .controls {
      position: absolute;
      left: 0;
      display: flex;
      margin-top: 10px;
      flex-direction: column;
      align-items: flex-start;
    }

    .radio-options {
      position: absolute;
      right: 0;
    }
  }
}
.regionSelect {
  width: 100px;
}
