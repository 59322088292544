@import "../../../../../assets/stylesheets/var";

.bargraph-body {
  position: relative;
  padding: 10px;

  .search-bar{
    padding-right: 20px;
    padding-bottom: 1%
  }

  .sub-text {
    min-width: 80px;
    margin: 13px 5px 0 0;
    font-size: 0.813rem;
    height: 0.5rem;
    display: flex;
    align-items: center;
    color: $graph-axis-color;
    font-weight: 600;
    word-break: break-word;
    hyphens: auto;
  }

  .sub-text.text-center {
    justify-content: center;
  }
  .bargraph-content {
    height: inherit;
    padding: 10px 0;
    width: 85% !important;
  }

  .page-name {
    cursor: pointer;
    margin-right: 1em;
    color: $secondary-text;
    font-family: $quicksand;
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 130%; /* 15.6px */
    letter-spacing: 0.504px;
  }

  .page-name.selected {
    color: $primary-color;
  }

  .custom-checkbox {
    display: none; /* Hide the default checkbox */
  }

  .settings-button {
    border: none;
    background: none;
    cursor: pointer;
  }

  .set-margin {
    margin: 0 10px 0 10px;
  }
  .menuFilter {
    border-radius: 10px;
    border: 1px solid $tert-text;
    background: $background-1;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    position: absolute;
    width: fit-content;
    top: 40px;
    right: 0px;
    z-index: 1;
  }

  #tooltip-for-bargraph a {
    color: $background;
  }
  .labels-list {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    height: 100%;
    padding-top: 25px;
    div {
      font-size: 0.8rem;
      font-family: $quicksand;
      text-align: right;
      font-weight: 500;
    }
  }

  .visitors-list {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    div {
      font-size: 0.9rem !important;
      font-family: $quicksand;
      text-align: right;
      font-weight: 500;
    }
    .comparison-value {
      font-size: 11px !important;
    }
  }

  .nodata-text {
    font-size: 1rem;
    color: $secondary-text;
    font-family: $quicksand;
    font-weight: 600;
    margin-top: 125px;
  }
}
