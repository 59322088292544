@import "../../../../../../assets/stylesheets/var";

.opened-image-model {
  .preview-close{
    padding-left: 20px;
    padding-top: 20px;
    padding-right: 20px;
  }
  .preview-text {
    font-family: $quicksand;
    font-weight: 700;
    font-size: 24px;
    line-height: 31.2px;
    letter-spacing: 4.2%;
  }
  .close-img {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  .image-section-main-div {
    margin-top: 27px;
    padding-top: 27px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 27px;
    display: flex;
    justify-content: center;  
  }
  .name-dimensions {
    padding: 20px;
    .image-name {
      font-family: $quicksand;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: $primary-text;
      padding-bottom: 15px;
    }
    .download-icon-div{
        cursor: pointer;
        .download-icon {
            width: 20px;
            height: 20px;
            margin-right: 7px;
        }
    }
    .dimensions {
      font-family: $quicksand;
      font-weight: 500;
      font-size: 14px;
      line-height: 17.5px;
      letter-spacing: 1.5%;
      color: $secondary-text;
    }
  }
}
