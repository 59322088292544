@import "../../../../assets/stylesheets/var";

.my-websites {
  .delete-button-main{
    width: 50px;
    height: 50px;
    cursor: pointer
  }
  .search-bar-websites{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 10px;
    .search-bar-input{
      margin-right: 5%;
      position: relative;
      bottom: 0.4px
    }
    .search-filter-websites{
      position: relative;
      bottom: 8px;
      margin-right: 12px
    }
  }
  input[type="checkbox"] {
    appearance: none;
    cursor: pointer;
    width: 20px;
    height: 20px;
    border: 2px solid $fourth-text;
    border-radius: 4px;
  }
  input[type="checkbox"]:before {
    content: "";
    display: block;
    width: 80%;
    height: 80%;
    margin: 2px;
    border-radius: 2px;
  }
  input[type="checkbox"]:checked::before {
    background-color: $graph-axis-color
  }
  min-height: 80vh;
  display: grid;
  grid-template-columns: 70%;
  grid-template-rows: 10% 90%;
  align-items: start;
  justify-content: center;
  gap: 20px;
  padding: 4% 0;
  // background-color: $background-1;

  img {
    height: 24px;
    width: 24px;
  }
  // .delete-icon {
  //   width: 50px;
  //   height: 70px;
  //   cursor: pointer;
  // }
  .title {
    font-family: $quicksand;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; 
    letter-spacing: 1.008px;
    color: $primary-text;
  }

  .websites-list {
    display: grid;
    grid-template-columns: 32.33% 32.33% 32.33%;
    gap: 20px 15px;

    .website-tile {
      min-height: 8.125rem;
      height: fit-content;
      cursor: pointer;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
      background-color: $background;
      padding: 10px;
      border-radius: 10px;

      .name {
        color: $primary-text;
        font-weight: 400;
        font-size: 15px;
      }

      .delete-icon-tile{
        width: 47px;
        height: 40px;
        position: relative;
        left: 12px
      }

      .info-pin-icons {
        img {
          margin-left: 5px;
        }
      }
      .tile-bottom {
        // padding-top: 10px;
        border-top: 2px solid;
        border-color: $background-1;
        .helper-text {
          color: $fourth-text;
        }
      }

      img.logo {
        width: 32px;
        height: 32px;
        margin-right: 15px;
      }
      img.mark-default {
        margin-right: 2.5px;
      }
      img {
        width: 24px;
        height: 24px;
      }

      input[type="radio"] {
        appearance: none;
        cursor: pointer;
        width: 20px;
        height: 20px;
        border: 2px solid $fourth-text;
        border-radius: 3px;
      }
      input[type="radio"]:before {
        content: "";
        display: block;
        width: 75%;
        height: 75%;
        margin: 15% auto;
        border-radius: 1px;
      }
      input[type="radio"]:checked::before {
        background-color: $fourth-text;
      }
    }

    .website-tile:hover {
      box-shadow: -2px 2px 15px 0px rgba(0, 0, 0, 0.2);

      .tile-bottom {
        border-color: $tert-text;
      }
    }
  }
}

.my-websites-modal {
  .web-link {
    color: $primary-text;
    font-size: 0.75rem;
    font-weight: 600;
  }

  .copy-icon {
    cursor: pointer;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    padding: 10px;
    // background-color: $background-1;
  }

  .snippet {
    color: $primary-text;
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 130%; /* 26px */
    letter-spacing: 0.8px;
    .helper-text {
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.8px;
    }
  }

  .snippet-text {
    margin-top: 10px;
    // padding: 20px 30px;
    border-width: 2px;
    border-style: solid;
    border-radius: 10px;
    // background-color: $background-1;
    color: $primary-text;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.8px;
  }

  form {
    height: inherit;
    margin-top: 2px;
  }
  .grid {
    gap: 10px;
  }
  .MuiPaper-root.MuiDialog-paper {
    min-height: 72vh;
    height: 72vh;
    padding: 15px;
  }
  img {
    height: 24px;
    width: 24px;
  }
  .MuiTypography-root.MuiDialogTitle-root {
    padding: 0;
  }
  input[type="checkbox"] {
    width: 18px;
    height: 18px;
    border: 2px solid $primary-color;
  }
  input[type="checkbox"]:checked::before {
    background-color: $primary-color;
  }
}

// Tablet media queries

/* Medium devices (tablets, 768px and up) */
@media (max-width: 1200px) {
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 768px) {
  .my-websites {
    min-height: 120vh;
  }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 576px) {
}

// Tablet media queries ends


// Mobile media queries

/* Extra Small Devices, Phones */
@media (max-width: 320px) {
  .my-websites {
    min-height: 1000vw;
  }
}

/* Small Devices, Phones in Landscape */
@media (min-width: 320px) and (max-width: 480px) {
  .my-websites {
    min-height: 200vh;
  }
}

/* Small Devices, Phones in Portrait */
@media (min-width: 481px) and (max-width: 600px) {
 
}

// Mobile media queries ends
