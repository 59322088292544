@media screen and (max-width: 768px) {
  html {
    font-size: 10px;
  }
  .flex {
    // flex-wrap: wrap;
  }
  .flex-between {
    flex-wrap: wrap;
  }
  .col-100 {
    flex: 0 1 100%;
    margin: 2.5rem;
  }
  .column {
    flex-direction: column;
  }
  .order-1 {
    order: -1;
  }
}
