@import "../../assets/stylesheets/var";

.toggle {
  align-items: center;

  h3 {
    color: $primary-text;
    margin-right: 1.5rem;
    font-size: 1rem;
  }

  .outer-div {
    width: 3.6rem;
    height: 1.5rem;
    background-color: $fourth-text;
    border-radius: 2rem;
    cursor: pointer;
    position: relative; /* Add position relative to the parent */

    .inner-div {
      width: 1.1rem;
      height: 1.1rem;
      border-radius: 50%;
      background-color: $background;
      position: absolute; /* Position the inner-div absolutely */
      top: 0.19rem; /* Adjust the top position to vertically center the inner-div */
      left: 0.3rem; /* Set the initial left position */
      transition: transform 0.3s ease; /* Add transition for smooth animation */
    }
    .active {
      transform: translateX(2rem); /* Adjust the translation value as needed */
    }
  }
  .toggle-on {
    background-color: $primary-color-2;
  }
}
