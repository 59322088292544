@import "../../../../assets/stylesheets/var";

.manage-campaign-heading {
  font-family: $quicksand;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 1.008px;
  color: $primary-text;
}
 .all-campaigns-heading{
  font-weight: 700;
  font-size: 16px;
  line-height: 20.7px;
  letter-spacing: 4.2%;
  padding-left: 14px;
 }

.delete-campaign-button {
  cursor: pointer;
  border: 0;
  outline: 0;
  background: none;
  img {
    width: 24px;
    height: 24px;
  }
}

.manage-campaign-image {
  width: 802.53px;
  height: 585.31px;
}

.delete-multiple {
  background: url("../../../../assets/icons/buttons.svg") no-repeat;
  border: none;
  width: 50px;
  cursor: pointer;
}
