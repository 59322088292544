@import "../../../../../../assets/stylesheets/var";


.upload-images-model{
    .close-img-div {
        display: flex;
        justify-content: flex-end;
        padding-right: 20px;
        padding-top: 20px;
        .close-img {
          width: 20px;
          height: 20px;
          cursor: pointer;
        }
      }
      .upload-images-text{
        font-family: $quicksand;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 4.2%;
        padding-left: 20px;
        padding-top: 20px;
        color: $primary-color;
      }
      .select-image{
        font-family: $quicksand;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 5%;
        padding-left: 20px;
        padding-top: 20px;
        padding-bottom: 20px;
        color: $secondary-text
      }

      .cancel-proceed-buttons{
        margin: 40px;
        .cancel-right{
            padding-right: 17px;
        }
      }
}