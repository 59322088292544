@import "../../../assets/stylesheets/var";

.search-bar-main{
  position: relative;
  display: inline-block;
  .input-placeholder{
    position: relative;
    margin-top: 1%;
    margin-right: 20%;
    padding-left: 50px;
    padding: 8px;
    width: 13rem;
    border-radius: 9px;
    font-family: $quicksand; // Replace with the appropriate variable for themeColors.fontQuickSand
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    border: 1.5px solid #ccc;
    &::placeholder {
      color: #aaa; /* Change this to your desired placeholder color */
      font-style: normal; /* Change this to your desired placeholder style */
      opacity: 1; /* This ensures the opacity is 100% */
      font-weight: 400;
    }
  } 
  .search-div{
    padding-left: 7px;
    background: none;
    border: none;
    cursor: pointer;
    .search-icon{
      width: 24px;
      height: 24px;
      position: absolute;
      top: 27%;
      right: 4px
    }
  } 
  .clear-div{
    background: none;
    border: none;
    cursor: pointer;
    .clear-icon{
      width: 15px;
      height: 15px;
      position: absolute;
      right: 10px;
      top: 34%
    }
  }
}

 /* For tablets */
 @media (width: 768px) {
  .search-bar-main{
    .search-div{
      .search-icon{
        position: absolute;
        top: 17%;
      }
    } 
    .clear-div{
      .clear-icon{
        position: absolute;
        top: 23%
      }
    }
  }
}
  