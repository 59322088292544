@import "../../../../../../assets/stylesheets/var";

.campaign-tile {
  position: relative;
  display: flex;
  justify-content: space-between;
  border: 1px solid $tert-text;
  border-radius: 10px;
  background-color: #fff;
  overflow: hidden;
  cursor: pointer;
  .left-rectangle,
  .right-rectangle {
    background-color: $primary-color-4;
    position: absolute;
    transform-origin: top left;
    transform: scaleX(1) scaleY(1);
    transition: width 0.4s;
  }

  .left-rectangle {
    top: -20px;
    left: 0;
    width: 200px;
    height: 140px;
    transform: rotate(45deg);
    border-radius: 20px;
  }

  .right-rectangle {
    top: -150px;
    right: -100px;
    width: 200px;
    height: 190px;
    transform: rotate(59deg);
    border-radius: 50px;
  }

  &:hover {
    background-color: $primary-color-4;
    .left-rectangle,
    .right-rectangle {
      display: none;
    }
  }

  .campaign-details {
    z-index: 1;
    flex-grow: 1;

    .campaign-details-text {
      margin: 0;
      padding-left: 20px;
      padding-top: 20px;
      padding-bottom: 10px;
      font-weight: 700;
      font-size: 16px;
      line-height: 20.7px;
      letter-spacing: 4.2%;
      color: $primary-color;
    }

    .campaign-info {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;

      .info-item {
        display: flex;
        flex-direction: column;
        margin-right: 20px;

        .info-item-key {
          padding-left: 20px;
          padding-bottom: 7px;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: $secondary-text;
        }

        .info-item-value {
          padding-left: 20px;
          padding-bottom: 20px;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          color: $primary-color;
        }
      }
    }
  }

  .campaign-image {
    z-index: 1;
    width: 250px;
    height: auto;
    margin-left: 20px;
  }
}
