@import "../../assets/stylesheets/var";

.dashboard-body {
  h6 {
    font-family: $quicksand;
    font-weight: 700;
  }
  input[type="radio"] {
    appearance: none;
    cursor: pointer;
    width: 24px;
    height: 24px;
    border: 3px solid $primary-color-2-shade-2;
    border-radius: 50%;
  }
  input[type="radio"]:before {
    content: "";
    display: block;
    width: 70%;
    height: 70%;
    margin: 15% auto;
    border-radius: 50%;
  }
  input[type="radio"]:checked::before {
    background-color: $primary-color-2-shade-2;
  }

  .banner-notification {
    border-radius: 10px;
    background: $primary-color-4;
    padding: 15px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    color: $primary-text;
    font-family: $quicksand;
    font-size: 12px;
    font-weight: 600;
    line-height: 130%; /* 15.6px */
    letter-spacing: 0.504px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .banner-close {
    border: none;
    background: none;
    cursor: pointer;
  }

  .filter-header {
    position: sticky;
    z-index: 40;
    top: 0;
    // background-color: $background-1;
    // padding: 5px 0 5px 0;
  }
  .grid-template {
    // grid-template-columns: 50% 50%;
    grid-template-columns: 32.3% 32.3% 32.3%;
    gap: 10px;
    justify-content: space-between;
  }

  .grid-template-comparison {
    grid-template-columns: 49% 50%;
    gap: 10px;
  }
  .grid-template-2 {
    grid-template-columns: 60% 40%;
    gap: 10px;
  }

  .pop-up-button {
    background-color: inherit;
    border: none;
    cursor: pointer;
  }

  .arrow {
    height: 25px;
    width: 25px;
  }
  .comparison-value {
    font-family: $quicksand;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .decreasing-value {
    color: $status-negative;
    line-height: 38.8px;
  }
  .increasing-value {
    color: $status-positive;
    line-height: 38.8px;
  }

  .radio-options {
    label {
      display: flex;
      align-items: center;
      font-family: $quicksand;
      font-weight: 500;
      color: $primary-text;
    }
  }

  
  .heading3{
    font-family: $quicksand;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 31.2px */
    letter-spacing: 1.008px;
    color: $primary-text;
  }

  .body-heading{
    font-family: $quicksand;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 31.2px */
    letter-spacing: 0.672px;
    color: $primary-text;
  }
}
