@import "../../assets/stylesheets/var";

.persona-label-section {
  box-shadow: 0px 4px 10px 0px #0000001a;
  background: $background;
  padding: 10px;
  border-radius: 10px;
  h6 {
    color: $primary-text;
    font-weight: 700;
    margin-bottom: 11.5px;
  }
  p {
    font-size: 19px;
    color: $secondary-text;
    letter-spacing: 1%;
  }
}
