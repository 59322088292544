@import "../../../../../../assets/stylesheets/var";


.select-types {
    margin-left: 1%;
    margin-right: 1%;
    margin-bottom: 2%;
    width: 450px;
    height: 600px;
    border-radius: 7px;
    border-width: 1px;
    border-style: solid;
    background-color: rgba(255, 255, 255, 1);
    border-color: rgba(158, 172, 95, 1);
    &.isHovered {
      background-color: rgba(
        232,
        240,
        197,
        1
      );
    }
    &.isPersona {
      margin-right: 1%;
      background-color: rgba(
        232,
        240,
        197,
        1
      );
    }
    .step-text {
      padding-left: 4%;
      padding-top: 7%;
      font-family: $quicksand;
      font-weight: 600;
      font-size: 16px;
      color: rgba(52, 78, 65, 1);
    }
    .scrollable-container {
      height: 470px;
      overflow-y: auto; 
    }    
    .select-types-text {
      padding-left: 4%;
      padding-top: 7%;
      font-family: $quicksand;
      font-weight: 700;
      font-size: 20px;
      color: rgba(52, 78, 65, 1);
    }
    .persona-checkbox {
      margin-bottom: 10px;
      background-color: $background; 
      border: 1px solid #ccc;
      border-radius: 5px;
      padding: 10px;
      height: 47px;
      margin-left: 4%;
      margin-right: 4%;
      margin-top: 4%;
      &.isSelected {
        background-color: rgb(105, 142, 124);
      }
    }
    .persona-icon {
      width: 36px;
      height: 36px;
      margin-right: 10px;
    }
    .persona-name {
      font-family: $quicksand;
      font-weight: 500;
      font-size: 16px;
    }
    .margins-no-selections {
      margin-left: 4%;
      margin-top: 4%;
    }
    .types-dropdown {
      margin-left: 4%;
      margin-right: 4%;
      margin-top: 4%;
    }
    .margins-selections {
      margin-left: 4%;
      margin-top: 7%;
    }
    .selected-background {
      height: 47px;
      margin-bottom: 2%;
      margin-right: 4%;
      border-radius: 7px;
      cursor: pointer;
      background-color: rgba(255, 255, 255, 1); 
      &.selected {
        background-color: rgba(
          105,
          142,
          124,
          1
        ); 
      }
    }
    .icon-div {
      margin-left: 4%;
      margin-right: 2%;
      .icon {
        width: 36px;
        height: 36px;
      }
    }
    .type-name {
      font-family: $quicksand;
      font-weight: 500;
      font-size: 16px;
      color: rgba(52, 78, 65, 1);
      &.selected {
        color: $background;
      }
    }
  }