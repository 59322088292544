@import "../../assets/stylesheets/var";

.authorized-header {
  .header-container {
    min-width: 1500px;
    width: max-content;
    background-color: $primary-color;
    margin: 0 auto;
    padding: 0.75rem 0;
  }
  background-color: $primary-color;
  .header-section {
    .dropdown-page {
      ul {
        align-items: center;
        .home-icon {
          width: 2.5rem;
          height: 2.5rem;
          object-fit: contain;
          margin-right: 0.9375rem;
        }
        .nav-hr {
          width: 0.125rem;
          height: 1.25rem;
          background-color: $background;
          border: none;
          margin: 0;
        }
      }
      .normal-gap {
        gap: 0.9375rem;
      }
      .max-gap {
        gap: 68.75rem;
      }
    }
    .hamburger-notification {
      align-items: center;
      gap: 1.25rem;
      position: relative;
      .notificationWithCount {
        position: relative;
      }
      .notification-icon {
        cursor: pointer;
        // width: max-content;
      }
      .notification-count {
        cursor: pointer;
        // width: max-content;
      }
      @media only screen and (max-width: 768px) {
        .notification-icon,
        .notification-count {
          justify-content: center;
          align-items: center;
          width: 30%; /* Width when the website window is shrunk */
          max-height: 38vw;
          margin-left: 2px;
          margin-right: 4px;
          margin-top: 4%;
        }
        .authorized-header {
          .header-container {
            min-width: 5000px;
            width: max-content;
            background-color: $primary-color;
            margin: 0 auto;
            padding: 1.7rem 0;
          }
        }
      }

      .count {
        position: absolute;
        top: 0;
        right: 0;
        color: $background-1;
        background-color: $status-negative;
        border-radius: 50%;
        width: 18px;
        height: 18px;
        text-align: center;
      }

      /* For tablets */
      @media (max-width: 1023px) and (min-width: 768px) {
        /* Your styles for tablets here */
        .authorized-header {
          .header-container {
            min-width: 5000px;
            width: max-content;
            background-color: $primary-color;
            margin: 0 auto;
            padding: 1.7rem 0;
          }
        }
      }
    }
  }
}
