@import "../../assets/stylesheets/var";

.tagMain {
  margin: 0 0 3rem 0;
  width: 100%;
}

.tag-manager{
  width: 100%;
}

.custom-main{
  position: relative;
  cursor: pointer;
  .active-icon{
    position: absolute;
    top: 8px;
    right: 29px
  }
  .active-image{
    width: 20px;
  }
  .custom-check-box{
    position: absolute;
    top: 8px;
    right: 12px
  }
  .custom-tag-text{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    &.selected{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
      color: $background;
    }
  }
}

.custom-tags {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-items: center;
  width: 100%;
  padding-top: 1%;
  padding-bottom: 2%;
  margin-bottom: 3%;
}

.custom-width {
  width: 12.5rem;
  height: 45px;
  border-radius: 10px;
  border-style: solid;
  border-width: 1.5px;
  border-color: $primary-color;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 7px;
  &.selected{
    background-color: $primary-color;
  }
  &.hovered{
    background-color: $primary-color-4;
  }
}

.tagManager {
  padding-top: 3rem;
  .marginTop{
    margin-top: 1%;
  }
  .marginTopDataTable{
    padding-top: 27px;
  }

  .data-table,
  .add-tag-form-box {
    width: 100%;
    background-color: $background;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;

    .half-column-grid {
      display: grid;
      grid-template-columns: 49% 49%;
      gap: 20px;
    }

    .add-tag-form-grid {
      grid-template-columns: auto;
      gap: 20px;
      padding: 2% 0;
    }
  }
  .tag-manager-heading {
    font-family: $quicksand;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 31.2px */
    letter-spacing: 1.008px;
    color: $primary-text;
  }
}

// Tablet media queries

/* Medium devices (tablets, 768px and up) */
@media (max-width: 1200px) {
  .custom-tags {
    // grid-template-columns: repeat(3, 1fr);
    // margin-left: 10%;
    // margin-right: 10%;
  }
  .custom-width {
    width: 10rem;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 768px) {
  .custom-tags {
    grid-template-columns: repeat(3, 1fr);
    // margin-left: 5%;
    // margin-right: 5%;
  }
  .custom-width {
    width: 14rem;
  }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 576px) {
  .custom-tags {
    grid-template-columns: 1fr;
    margin-left: 2%;
    margin-right: 2%;
  }
  .custom-width {
    width: 10rem;
  }
}

// Tablet media queries ends

