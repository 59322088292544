@import "../../../../../assets/stylesheets/var";

.user-management {
  .table {
    margin-top: 10px;
  }
  .select-options{
    min-width: 240px;
    font-weight: 500;
    font-size: 16px;
    color: $primary-text;
    font-family: $quicksand;
  }
  .MuiPaper-root.MuiDialog-paper {
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 80vh;
  }
  .margin-right {
    margin-right: 10px;
  }

  .MuiTypography-root.MuiDialogTitle-root {
    padding: 16px 0;
  }

  .marginTop {
    margin-top: 30px;
  }
}

.select-role {
  border: none;
  width: 100%;
}

.table-cell {
  border-right: 2px solid $tert-text; /* Add a border between columns */
  padding: 8px !important; /* Add padding for better spacing */
  width: 50%;
  border-bottom: 2px solid $tert-text !important;
  border-bottom: none;
}

.table-cell-text{
  font-weight: 500;
  font-size: 16px;
  color: $primary-text;
  font-family: $quicksand;
}

.add-edit-user-text{
  font-weight: 700;
  font-size: 24px;
  font-family: $quicksand;
}

.users-labels-text{
  font-weight: 700;
  font-size: 16px;
  color: $secondary-text;
}

.table-cell-1 {
  padding: 8px !important; /* Add padding for better spacing */
  width: 25%;
  border-bottom: 2px solid $tert-text !important;
}
.table-cell-2 {
  padding: 8px !important; /* Add padding for better spacing */
  width: 50%;
  border-bottom: 2px solid $tert-text !important;
  border-left: 2px solid $tert-text !important;
}

.table-website {
  border-radius: 10px;
  border: 2px solid $tert-text;
  background: $background;
  margin-top: 17px;
}

.role-management,
.role-desc,
.role-sub {
  margin-bottom: 10px;
}

.view-less {
  text-decoration: underline;
  cursor: pointer;
}

.role-button {
  color: var(--Button-color, $primary-color);
  border: none;
  background: none;
  font-size: 12px;
  font-style: normal;
  cursor: pointer;
  font-weight: 600;
  line-height: 130%; /* 15.6px */
  letter-spacing: 0.504px;
  text-decoration-line: underline;
}

.delete-icon {
  cursor: pointer;
}

.error-msg {
  color: red; /* Example: Set text color to red */
  font-size: 12px; /* Example: Set font size to 16 pixels */
  font-weight: bold; /* Example: Set font weight to bold */
  white-space: nowrap; /* Ensure single line */
}

.search-filter-user{
  position: relative;
  left: 40%
}

.search-bar-user{
  position: relative;
  left: 42.5%  ;
  top: 8px
}

// Tablet media queries

/* Medium devices (tablets, 768px and up) */
@media (max-width: 1200px) {
  .search-filter-user{
    position: relative;
    left: 52%
  }
  .search-bar-user{
    position: relative;
    left: 53.5%  ;
    top: 8px
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 768px) {
  .search-filter-user{
    position: relative;
    left: 62%
  }
  .search-bar-user{
    position: relative;
    left: 63.5%  ;
    top: 8px
  }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 576px) {
  
}

// Tablet media queries ends
