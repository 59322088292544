@import "../../../../assets/stylesheets/var";

.custom-calendar {
  width: 914px;
  height: 690px;
  background: $background;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 0.7rem 1rem;
  position: absolute;
  right: 0;
  // right: 15%;
  .close-icon {
    width: 1.2rem;
    height: 1.2rem;
    position: absolute;
    right: 1rem;
    cursor: pointer;
  }
  .calendar-options {
    margin-top: 1.9rem;
    h3 {
      color: $secondary-text;
      font-size: 1rem;
      font-weight: 500;
    }
    .calendar-toggle {
      p:nth-child(1) {
        margin-right: 1.3rem;
      }
      p {
        font-size: 0.85rem;
        color: $secondary-text;
        font-weight: 400;
        cursor: pointer;
      }
      .active {
        font-weight: 500;
        color: $primary-color;
      }
    }
  }
  .label-and-rendered-component-parent {
    margin-top: 2rem;
    justify-content: space-between;
    .label-list-parent {
      width: 21%;
      align-self: flex-start;
      .label-list-child {
        padding: 0.8rem 0.625rem;
        border-radius: 4px;
        margin-bottom: 0.4rem;
        cursor: pointer;
        .label {
          text-transform: capitalize;
          font-size: 0.9rem;
          color: $secondary-text;
        }
        .action-icon {
          display: none;
        }
      }
      .label-list-child:hover {
        background-color: $background-1;
        .action-icon {
          display: block;
          i {
            font-size: 1rem;
          }
          i:nth-child(1) {
            margin-right: 5px;
          }
        }
      }
      .label-list-child.selected {
        background-color: $primary-color;
        .label {
          color: $background;
        }
        .action-icon {
          display: block;
          i {
            color: $background;
          }
          i:nth-child(1) {
            margin-right: 5px;
          }
        }
      }
      .add-new {
        border: 0;
        outline: 0;
        background: $primary-color;
        color: $background;
        width: 11.57rem;
        border-radius: 4px;
        padding: 0.82rem 0.8rem;
        font-size: 0.9rem;
        text-align: left;
        cursor: pointer;
      }
      .unselected {
        color: $secondary-text;
        background-color: $background;
      }
      .unselected:hover {
        background-color: $background-1;
      }
    }
    .rendered-component {
      width: 76%;
    }
  }
}
