.grid,
.grid-gap {
  display: grid;
}

.grid-gap {
  gap: 20px;
}

.half-column-grid {
  display: grid;
  grid-template-columns: 49% 49%;
  gap: 20px;
}

.half-column-grid-30-70 {
  display: grid;
  grid-template-columns: 30% 70%;
  gap: 20px;
}

.half-column-grid-with-divider {
    display: grid;
    grid-template-columns: 49% 1% 49%;
    gap: 10px;
    height: 80vh;
    padding: 10px 0px
  }

.grid-center-100{
  display: grid;
  justify-content: center;
  align-items: center;
  height: 100%;
}
