@import "../../../../../assets/stylesheets/var";

.selectButton,.closeButton{
    background-size: 20px !important;
    width: 20px;
    height: 20px;
    border: none;
    border-radius: 10px;
}

.closeButton {
    background: url("../../../../../assets/icons/closeButton.svg") no-repeat;
}

.editButton,.deleteButton , .close-button{
    border: none;
    background:none;
    cursor: pointer;
}

.add-tag-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .popup-content {
    background-color: $background-1; 
    border-radius: 10px;
    position: relative;
    width: 80%; 
    height: 90%; 
    overflow: auto; 

    .close-icon {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 20px; 
      height: 20px; 
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }
  }
}