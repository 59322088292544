@import "../../../../../../assets/stylesheets/var";


.generated-designs {
    .get-categorised-images{
      cursor: pointer;
      padding-top: 12px
    }
    .get-images-loading{
      padding-left: 7px;
      padding-bottom: 12px
    }
    .get-images-main{
      margin-bottom: 20px;
      padding-left: 7px;
      padding-right: 7px;
    }
    padding-bottom: 1%;
    .edit-icon-img {
      border: none;
      cursor: pointer;
      width: 49px;
    }
    .margins {
      margin-left: 1%;
      margin-top: 1%;
    }
    .input-width {
      width: 50%;
    }
    .generated-designs-margins {
      margin-left: 1%;
      margin-top: 2%;
    }
    .buttons-padding{
      padding-top: 1%;
      padding-bottom: 1%;
    }
    .cancel-button-padding-right{
      padding-right: 1%;
    }
    .generated-designs-subtitle {
      font-family: $quicksand;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: "7%";
      color: $secondary-text;
      padding-top: 1%;
      padding-bottom: 1%;
    }
    .generated-designs-campaign-name {
      font-family: $quicksand;
      font-weight: 700;
      font-size: 16px;
      line-height: 20.7px;
      letter-spacing: "7%";
      color: $secondary-text;
      padding-top: 2%;
    }
    .close-icon {
      padding-top: 1%;
      padding-right: 1%;
    }
    .close-icon-img {
      border: none;
      cursor: pointer;
      width: 20px;
      position: relative;
      right: 0px;
    }
    .navigation-buttons {
      padding: 2%;
      .navigation-icons {
        border: none;
        cursor: pointer;
        width: 50px;
        height: 50px;
      }
    }
    .generated-images-number {
      font-family: $quicksand;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: $secondary-text;
    }
    .preview-background {
      background-color: $background-1;
      border-radius: 2px;
      margin-left: 1%;
      margin-top: 1%;
      margin-right: 1%;
      margin-bottom: 1%;
      .preview-text {
        font-family: $quicksand;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: $secondary-text;
        padding-top: 1%;
        padding-left: 7px;
      }
      .images-padding {
        padding-right: 1%;
      }
      .regenerate-text {
        font-family: $quicksand;
        font-weight: 500;
        font-size: 16px;
        color: $primary-color;
        padding-right: 7px;
      }
      .generate-icon-div {
        .generate-icon-img {
          border: none;
          cursor: pointer;
          width: 20px;
          position: relative;
          right: 0px;
          padding-top: 1%;
        }
      }
      .generated-images-background {
        background-color: $background;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        width: 370px;
        height: 490px;
        margin-left: 2%;
        margin-right: 2%;
        margin-top: 1%;
        margin-bottom: 2%;
        &.isHovered {
          background-color: $primary-color-4;
        }
        .edit-icon-img {
          border: none;
          cursor: pointer;
          width: 49px;
        }
      }
      .generated-images-margins {
        padding: 7%;
      }
      .generated-image {
        height: 370px;
        margin-bottom: 4%;
        .heading {
          padding-left: 10%;
          padding-top: 7%;
          font-family: $quicksand;
          font-weight: 700;
          font-size: 24px;
          line-height: 31.2px;
          letter-spacing: 4.2%;
          color: $background;
        }
        .title {
          padding-left: 10%;
          padding-top: 9%;
          font-family: $quicksand;
          font-weight: 700;
          font-size: 20px;
          line-height: 26px;
          letter-spacing: 4.2%;
          color: $background;
        }
        .subtitle {
          padding-left: 10%;
          padding-top: 7%;
          font-family: $quicksand;
          font-weight: 400;
          font-size: 16px;
          line-height: 20.7px;
          letter-spacing: 4.2%;
          color: $background;
        }
        .action-button-div {
          background-color: #ff8469;
          height: 47px;
          border-radius: 20px;
          margin-left: 9%;
          margin-right: 9%;
          margin-top: auto;
          margin-bottom: 4%;
          .action-button {
            font-family: $quicksand;
            font-weight: 700;
            font-size: 16px;
            line-height: 20.7px;
            letter-spacing: 4.2%;
            color: $background;
          }
        }
        .content {
          padding-left: 10%;
          padding-top: 2%;
          padding-right: 10%;
          padding-bottom: 10%;
          font-family: $quicksand;
          font-weight: 400;
          font-size: 16px;
          line-height: 20.7px;
          letter-spacing: 4.2%;
          color: $background;
        }
      }
    }
    .preview-campaign-background {
      background-color: $background-1;
      border-radius: 2px;
      margin-right: 1%;
      margin-top: 2%;
      .preview-campaign-text {
        font-family: $quicksand;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: $primary-text;
        background-color: $tert-text;
        width: 100%;
        height: 44px;
        display: flex;
        align-items: center;
        padding-left: 4%;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
      }
      .generated-images-margins {
        margin-bottom: 10%;
      }
      
      // Edit design
      .generated-image-edit {
        width: 320px;
        height: 370px;
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 5%;
        .heading {
          padding-left: 4%;
          padding-top: 4%;
          font-family: $quicksand;
          font-weight: 700;
          font-size: 24px;
          line-height: 31.2px;
          letter-spacing: 4.2%;
          color: $background;
        }
        .title {
          padding-left: 4%;
          padding-top: 2%;
          font-family: $quicksand;
          font-weight: 700;
          font-size: 20px;
          line-height: 26px;
          letter-spacing: 4.2%;
          color: $background;
        }
        .subtitle {
          padding-left: 4%;
          padding-top: 4%;
          font-family: $quicksand;
          font-weight: 400;
          font-size: 16px;
          line-height: 20.7px;
          letter-spacing: 4.2%;
          color: $background;
        }
        .action-button-div {
          background-color: #ff8469;
          height: 47px;
          border-radius: 20px;
          margin-left: 9%;
          margin-right: 9%;
          margin-top: auto;
          margin-bottom: 4%;
          .action-button {
            font-family: $quicksand;
            font-weight: 700;
            font-size: 16px;
            line-height: 20.7px;
            letter-spacing: 4.2%;
            color: $background;
          }
        }
        .content {
          padding-left: 4%;
          padding-top: 2%;
          padding-right: 10%;
          padding-bottom: 10%;
          font-family: $quicksand;
          font-weight: 400;
          font-size: 16px;
          line-height: 20.7px;
          letter-spacing: 4.2%;
          color: $background;
        }
      }
    }
}