@import "../../assets/stylesheets/var";
.onboarding-section {
  background-color: $background-1;
  .yoda-logo {
    justify-content: center;
    align-items: center;
    img {
      height: 4.3rem;
      width: 4.3rem;
      object-fit: contain;
    }
    h2 {
      margin-left: 1rem;
      font-size: 1.2rem;
      font-weight: 500;
      color: $primary-text;
    }
  }

  .spinner {
    justify-content: center;
    width: 36vw;
  }

  .notification-warning {
    background-color: $primary-color-4;
    padding: 1rem;
    border-radius: 4px;
    margin-top: 2rem;
    box-shadow: 0px 2px 4px 0px #0000001a;

    p {
      font-size: 14px;
      color: $primary-color;
    }
    i {
      color: $primary-color;
    }
  }
}
