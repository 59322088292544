html {
  font-size: 16px;
}

h1 {
  font-size: 4rem;
  line-height: 1.3;
}

h2 {
  font-size: 2.5rem;
  line-height: 1.3;
}

h3 {
  font-size: 1.5rem;
  line-height: 1.3;
}

h5 {
  font-size: 0.75rem;
}

p {
  font-size: 1rem;
  line-height: 1.3;
}

li {
  list-style: none;
}
