@import "../../../assets/stylesheets/var";
.light-btn {
  border: 0;
  outline: 0;
  color: $primary-color;
  background-color: $background;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 1.1rem 2rem;
  font-weight: bold;
  letter-spacing: 0.04rem;
  cursor: pointer;
}
.dark-btn {
  border: 0;
  outline: 0;
  color: $background;
  background-color: $primary-color;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  width: 196px;
  height: 48px;
  margin-left: 1rem;
  cursor: pointer;

  .flex {
    align-items: center;
    justify-content: center;
    img {
      width: 24px;
      height: 24px;
      margin-right: 5px;
    }
    p {
      font-size: 16px;
      font-weight: 600;
    }
  }
}
