@import "../../../../assets/stylesheets/var";

.limit-exceeded-background {
  width: 84%;
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  .limit-exceeded-icon {
    width: 450px;
    height: 300px;
    margin-top: 3rem;
  }
  .margin-container {
    margin-bottom: 3rem;
  }
    .comp-heading {
        color: $secondary-text;
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 130%; /* 31.2px */
        letter-spacing: 1.008px;
        margin-bottom: 0.5rem;
    }
    .comp-sub-heading {
        color: $fourth-text;
        font-size: 1rem;
        font-weight: 600;
        line-height: 130%; /* 31.2px */
        letter-spacing: 1.008px;
        margin-bottom: 1rem;
    }
    p {
        color: $background;
        font-size: 1rem;
        font-weight: 700;
        line-height: 130%; /* 31.2px */
        letter-spacing: 1.008px;
        }


  @media only screen and (max-width: 400px) {
    .limit-exceeded-background {
      /* Styles for smaller screens */
      width: 75%;
      margin-top: 15px;
      height: 37vw;
    }
  }
  
}
