@import "../../../../../assets/stylesheets/var";

.label-tag {
  font-family: $quicksand;
  font-weight: 700;
  line-height: 20.8px;
  color: $secondary-text;
}

.heading {
  color: $primary-text;
  font-size: 1.5rem;
  font-weight: 700;
}

.connection-text {
  font-family: $quicksand;
  color: $secondary-text;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 5%;
  padding-right: 7px;
  display: inline-block;
  vertical-align: top;
}

.note-text {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.nagative-status {
  color: $status-negative;
}

.head-padding {
  padding: 0 0 10px 0;
}

.connection-text-padding {
  padding: 0 5px 0 8px;
}

.left-padding {
  padding-left: 5px;
}

.button-gap {
  gap: 20px;
}

.img-style {
  height: 20px;
  width: 20px;
}

.regenrate-password,
.regenrate-token {
  color: $primary-color;
  font-weight: 500;
  cursor: pointer;
  text-decoration: underline;
}

.dotted-progress-container {
  --uib-size: 35px;
  --uib-color: $primary-color-2-shade-2;
  --uib-speed: 1.3s;
  --uib-dot-size: calc(var(--uib-size) * 0.24);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: var(--uib-size);
}

.dotted-progress,
.dotted-progress-container::before,
.dotted-progress-container::after {
  content: "";
  display: block;
  height: var(--uib-dot-size);
  width: var(--uib-dot-size);
  border-radius: 50%;
  background-color: var(--uib-color);
  transform: scale(0);
  transition: background-color 0.3s ease;
}

.dotted-progress-container::before {
  animation: pulse var(--uib-speed) ease-in-out calc(var(--uib-speed) * -0.375)
    infinite;
}

.dotted-progress {
  animation: pulse var(--uib-speed) ease-in-out calc(var(--uib-speed) * -0.25)
    infinite both;
}

.dotted-progress-container::after {
  animation: pulse var(--uib-speed) ease-in-out calc(var(--uib-speed) * -0.125)
    infinite;
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }
}

.manage-api-list {
  display: grid;
  grid-template-columns: 32.66% 32.66% 32.66%;
  gap: 20px 1%;

  .manage-api-tile {
    min-height: 8.125rem;
    height: fit-content;
    cursor: pointer;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    background-color: $background;
    padding: 10px;
    border-radius: 10px;

    .name {
      color: $primary-text;
      font-weight: 700;
    }

    .secondary-name {
      font-size: 12px;
      color: $primary-text;
      font-weight: 600;
    }

    .tile-bottom {
      padding-top: 10px;
      border-top: 2px solid;
      border-color: $background-1;
      .helper-text {
        color: $fourth-text;
      }
    }

    img.logo {
      width: 42px;
      height: 42px;
      margin-right: 15px;
    }
    img {
      width: 24px;
      height: 24px;
    }
    .subscription-type-height {
      height: 18px;
      padding: 3px 15px;
      border-radius: 9px;
    }

    .active-chip-color {
      background-color: $status-positive;
      min-width: 115px;
    }

    .active-disable-chip-color {
      background-color: $dark-yellow;
    }

    .not-integrated-chip-color {
      background-color: $light-gray;
    }
  }

  .manage-api-tile:hover {
    box-shadow: -2px 2px 15px 0px rgba(0, 0, 0, 0.2);

    .tile-bottom {
      border-color: $tert-text;
    }
  }
}
