/* src/SliderButton.css */
@import "../../../assets/stylesheets/var";

.slider-container {
    display: inline-block;
  }
  
  .switch {
    position: relative;
    display: inline-block;
    width: 48px; /* Change width to 48px */
    height: 24px; /* Change height to 24px */
  }

  .noti-button-name{
    color: $secondary-text;
    font-family: $quicksand;
    font-weight: 500;
    letter-spacing: 0.8px;
    margin-right: 10px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc; /* Grey background color by default */
    transition: 0.4s;
    border-radius: 12px; /* Adjust border-radius to make it a rounded rectangle */
  }
  
  .slider:before {
    position: absolute;
    content: '';
    height: 20px; /* Adjust height to 20px */
    width: 20px; /* Adjust width to 20px */
    left: 2px; /* Adjust positioning */
    top: 2px; /* Adjust positioning */
    background-color: white; /* White button color by default */
    transition: 0.4s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color: $primary-color-3; /* Green background color when checked */
  }
  
  input:checked + .slider:before {
    transform: translateX(24px); /* Adjust positioning */
    background-color: white; /* White button color when checked */
  }
  