@import "../../../../../assets/stylesheets/var";

.advertisement-styles-container {
  .advertisement-subtitle {
    color: $primary-text;
    font-family: $quicksand;
    margin-top: 20px;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 26px */
    letter-spacing: 0.84px;
  }

  .advertsiment-body-container {
    .advertisement-style-tile {
      color: $primary-text;
      font-family: $quicksand;
      margin-top: 25px;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 130%; /* 26px */
      letter-spacing: 0.84px;
    }
  }
  .button-spacing {
    margin-left: 10px;
  }
  .button-container {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .advertisement-color-continer {
    background-color: $background-1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    padding: 15px;
    margin-top: 15px;

    .advertisement-color-title {
      color: $secondary-text;
      font-family: $quicksand;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      letter-spacing: 0.84px;
    }
    .advertisement-color-button {
      display: flex;
      //   flex-direction: row;
      //   justify-content: space-between;
      //   align-items: center;
      border: 1px solid $fourth-text;
      border-radius: 10px;
      padding: 8px 10px;
      width: 200px;
      position: relative;
    }
    .advertisement-color-button-container {
      flex: 1;
      cursor: pointer;
      border: none;
      background: none;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .advertisement-color-button-text {
      color: $secondary-text;
      font-family: $quicksand;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      letter-spacing: 0.84px;
    }
    .advertisement-color-picker {
      width: 20px;
      height: 20px;
    }

    .advertisement-color-selecter {
      position: absolute;
      top: 40px;
      left: -2px;
      width: 202px;
      background: white;
      border-radius: 10px;
      z-index: 100;
      padding-bottom: 20px;
      max-height: 400px;
      overflow-y: scroll;
    }
    .advertisement-color-selecter-close {
      display: flex;
      justify-content: flex-end;
    }
    .advertisement-color-default-color {
      display: flex;
      justify-content: center;
    }

    .advertisement-color-default-color-button {
      cursor: pointer;
      border: 1px solid grey;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      margin-right: auto;
      margin-left: auto;
      width: 80%;
      margin-top: 10px;
      padding: 8px;
    }
    .advertisement-color-selecter-close-button {
      cursor: pointer;
      border: none;
      background: none;
      display: flex;
      padding: 8px;
    }

    .advertisement-color-selecter-button {
      cursor: pointer;
      border: none;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      margin-right: auto;
      margin-left: auto;
      width: 80%;
      margin-top: 10px;
      padding: 8px;
    }
    .advertisement-color-selecter-container {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    .advertisement-color-default-color-container {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      // background-color: $primary-color-2;
    }
    .advertisement-font-button {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border: 1px solid $fourth-text;
      border-radius: 10px;
      //   padding: 0px 10px;
      //   width: 220px;
    }
    .advertisement-font-down-arrow {
      width: 40px;
      height: 40px;
    }
    .close-img {
      height: 20px;
    }
  }
}

.rcp-root {
  --rcp-background-color: #121212;
  --rcp-field-input-color: #ffffff;
  --rcp-field-input-border-color: #242424;
  --rcp-field-label-color: #808080;
}
.rcp {
  display: flex;
  flex-direction: column;
  background-color: var(--rcp-background-color);
  border-radius: 10px;
}
.rcp-body {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 10px;
}
.rcp-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.rcp-interactive {
  width: 100%;
  height: 100%;
  user-select: none;
  touch-action: none;
}
.rcp-interactive[aria-disabled="true"] {
  cursor: unset;
  pointer-events: none;
}
.rcp-saturation {
  cursor: all-scroll;
  width: 100%;
  position: relative;
  background-image: linear-gradient(to bottom, transparent, black),
    linear-gradient(to right, white, transparent);
  border-radius: 10px 10px 0 0;
}
.rcp-saturation-cursor {
  position: absolute;
  width: 20px;
  height: 20px;
  border: 2px solid white;
  border-radius: 100%;
  box-shadow: 0 0 15px #00000026;
  transform: translate(-10px, -10px);
}
.rcp-hue {
  cursor: ew-resize;
  position: relative;
  width: 100%;
  height: 12px;
  background-image: linear-gradient(
    to right,
    red,
    #ff0,
    #0f0,
    #0ff,
    #00f,
    #f0f,
    red
  );
  border-radius: 10px;
}
.rcp-hue-cursor {
  position: absolute;
  width: 20px;
  height: 20px;
  border: 2px solid white;
  border-radius: 100%;
  box-shadow: 0 0 15px #00000026;
  transform: translate(-10px, -4px);
}
.rcp-alpha {
  cursor: ew-resize;
  position: relative;
  width: 100%;
  height: 12px;
  border-radius: 10px;
}
.rcp-alpha-cursor {
  position: absolute;
  width: 20px;
  height: 20px;
  border: 2px solid white;
  border-radius: 100%;
  box-shadow: 0 0 15px #00000026;
  transform: translate(-10px, -4px);
}
.rcp-fields {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.rcp-fields-floor {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 10px;
}
.rcp-field {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.rcp-field-input {
  width: 100%;
  font-family: inherit;
  font-size: 14px;
  text-align: center;
  color: var(--rcp-field-input-color);
  background-color: transparent;
  border: 2px solid var(--rcp-field-input-border-color);
  border-radius: 5px;
  outline: none;
  padding: 5px 0;
}
.rcp-field-input:read-only {
  opacity: 0.8;
}
.rcp-field-label {
  font-size: 14px;
  text-align: center;
  color: var(--rcp-field-label-color);
}

.color-picker-container {
  padding: 0px;
}
