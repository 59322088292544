@import "../../../../assets/stylesheets/var";

/* dateRange.scss */
.custom-date-range {
  .selected-range {
    min-width: 16.5rem;
    display: inline-block;
    padding: 0.5rem 1rem;
    border: 1px solid #000;
    background-color: #fff;
    border-radius: 8px;
    cursor: pointer;
    .date-range-display {
      font-family: $quicksand;
      color: #000;

      .range1{
        color: $secondary-color;
      }

      .range2{
        color: $primary-color-2-shade-2;
      }

      .icon {
        vertical-align: middle;
        margin-left: 8px;
        i {
          font-size: 20px;
        }
      }
    }
  }
 
  .custom-calendar {
    .rdrDefinedRangesWrapper {
      .rdrStaticRanges {
        display: none;
      }
      .rdrInputRanges {
        display: none;
      }
    }
  }
}
