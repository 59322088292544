@import "../../../../assets/stylesheets/var";


.add-new {
  .add-action {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    right: 33%;
    top: 28%;
    img {
      width: 100%;
      margin-bottom: 1.2rem;
    }
  }
}
