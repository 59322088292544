.App{
    position: relative;
    min-height: 70vw;
    box-sizing: inherit;
}

/* For tablets */
@media (max-width: 1024px) and (min-width: 768px) {
    /* Your styles for tablets here */
    .App{
        position: relative;
        min-height: 200vw;
        box-sizing: inherit;
    }
}

/* For tablets */
@media (width: 768px) {
    /* Your styles for tablets here */
    .App{
        position: relative;
        min-height: 270vw;
        box-sizing: inherit;
    }
}