@import "../../../../assets/stylesheets/var";

.api-placeholder-section {
  background-color: $background;
  box-shadow: 0px 4px 10px 0px #0000001a;
  height: 37.5rem;
  border-radius: 10px;
  margin-bottom: 7%;
  .api-placeholder {
    text-align: center;
    img {
      width: 28.25rem;
      height: 28.125rem;
    }
    h3 {
      color: $tert-text;
      font-size: 1.5rem;
      font-weight: 600;
      margin-bottom: 15px;
    }
    p {
      color: $primary-text;
      font-size: 1rem;
      font-weight: 600;
    }
  }
}
