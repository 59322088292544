@import "../../../../assets/stylesheets/var";

.multibar-body{
    padding: 10px;
    .chart-container{
        height: 200px;
    }
    .chart-container.compare{
        display: flex;
        width: 50%;
    }
}