@import "../../../../assets/stylesheets/var";

.placeholder-background {
  width: 84%;
  margin-top: 3rem;
  // height: 24vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  img {
    width: 160px;
    height: 180px;
  }

  .coming-soon,
  .add-website {
    width: 387px;
    height: 238px;
}
  .add-web-link{
    cursor: pointer;
    color: $primary-color;
    font-weight: 600;
    font-size: 1rem;
    text-decoration: underline;
  }

  img.loading-gif {
    width: 288px;
  }

  

  div {
    color: $fourth-text;
    font-family: $quicksand;
    font-weight: 600;
    span {
      color: $secondary-text;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 130%; /* 31.2px */
      letter-spacing: 1.008px;
      margin-bottom: 1rem;
    }
  }

  @media only screen and (max-width: 400px) {
    .coming-soon,
    .add-website {
      width: 'auto'; /* Change width when window is shrunk */
      height: auto; /* Change height when window is shrunk */
    }

    .placeholder-background {
      /* Styles for smaller screens */
      width: 75%;
      margin-top: 15px;
      height: 37vw;
    }
  }

  /* For tablets */
  // @media (max-width: 1023px) and (min-width: 768px) {
  //   .coming-soon,
  //   .add-website {
  //     width: auto; /* Change width when window is shrunk */
  //     height: 47vw;
  //   }

  //   .placeholder-background {
  //     /* Styles for smaller screens */
  //     // width: 75%;
  //     // margin-top: 15px;
  //     height: 47vw;
  //   }
  // }
  
}
