@import "../../../../assets/stylesheets/var";

.edit-custom-calendar {
  .label-detail {
    margin-bottom: 1.3rem;
    h3 {
      font-size: 1.5rem;
      font-weight: 500;
      color: $secondary-text;
      text-transform: capitalize;
    }
  }
}
