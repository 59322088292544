@import "../../../../../assets/stylesheets/var";

.notification-main {
    margin:35px 0 50px 0;
    .notification-card{
      
        .breakCard{
            border-radius: 20px;
            opacity: 0.3;
            background: $tert-text;
            height: 2px;
        }
       .notification-title{
        margin-bottom: 10px;
        color: $secondary-text;
        font-family: $quicksand;
        font-weight: 700;
        line-height: 130%; /* 20.8px */
        letter-spacing: 0.672px;

       }    
       .grid-notification{
        display: grid;
        grid-template-columns: 70% 30%;
        margin:20px 0 20px 0;
       }
       .notification-header{
        color: $primary-text;
        font-family: $quicksand;
        font-weight: 700;
        line-height: 130%; /* 20.8px */
        letter-spacing: 0.672px;
        margin-bottom: 20px;
       }

       .notification-body{
        color: $secondary-text;
        font-family: $quicksand;
        font-weight: 500;
        letter-spacing: 0.8px;
        
       }
    
    }
}

.notification-subtitle{
    color: $primary-text;
    font-family: $quicksand;
    margin-top: 20px;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; /* 26px */
    letter-spacing: 0.84px;
}
