@import "../../assets/stylesheets/var";


.MuiPaper-root.MuiAccordion-root{
    box-shadow: none;
}

.accordian-style{
    position: relative;
    bottom: 7px
}

.settings-title {
    color: $primary-text;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 130%;
    letter-spacing: 1.008px;
  }

.MuiPaper-root.MuiAccordion-root:before{
    display: none;
}

.MuiAccordionSummary-root{
    padding: 0 !important;
}
  
.MuiAccordionDetails-root{
    padding: 0 !important;
    box-shadow: none;
}
  
.MuiAccordionDetails-root:before{
    display: none;
}

.MuiPaper-root.MuiAccordion-root.Mui-expanded{
    margin: 0 !important;
}