@import "../../../../../assets/stylesheets/var";

.forgot-password-section {
  background-color: $background-1;
  .heading-bottom{
    margin-bottom: 1.5rem;
  }
  .container {
    // max-width: 1296px;
    background-color: $background-1;
  }
  .forgot-password-image {
    width: 40.5rem;
    img {
      width: 100%;
      // height:  57.25rem;
      object-fit: cover;
      border-radius: 20px 0 0 20px;
    }
  }
  .forget-password-form {
    width: 40.5rem;
    // height: 57.25rem;
    box-shadow: -16px 4px 15px 0px rgba(0, 0, 0, 0.15);
    background-color: $background;
    padding: 2.5rem 3.12rem 2.5rem 3.12rem;
    border-radius: 0 20px 20px 0;
    .flex-column {
      gap: 27.2rem;
    }
    .heading {
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 700;
      letter-spacing: 0.063rem;
      margin-bottom: 1.88rem;
      color: $primary-text;
    }
    .sub-text {
      font-size: 1rem;
      font-weight: 600;
      color: $primary-text;
      margin-bottom: 1.25rem;
    }

    input {
      border: 2px solid $fourth-text;
      margin-top: 0.34rem;
    }
    label {
      color: $primary-text;
      font-size: 1rem;
      font-weight: 600;
    }
    button {
      padding: 0.625rem 1.5rem;
      border-radius: 0.375rem;
      background-color: $primary-color;
      color: $background;
      width: 100%;
      outline: 0;
      border: 0;
      font-size: 1rem;
      font-weight: 600;
      margin: 1.88rem 0;
      cursor: pointer;
    }
    .signup-redirect {
      margin-bottom: 2.69rem;
      p {
        font-size: 1rem;
        font-weight: 600;
        color: $primary-text;
        span {
          color: $primary-color-2-shade-2;
          cursor: pointer;
        }
      }
    }
    .forgot-password-bottom {
      .bottom-details {
        p {
          color: $secondary-text;
          font-size: 0.75rem;
          font-weight: 400;
          margin-bottom: 0.31rem;
        }
        .policies {
          gap: 1.88rem;
        }
      }
      .language-change-forgot {
        background-color: $background-1;
        padding: 0.75rem 1rem;
        border-radius: 10px;
        .default-icon {
          gap: 1rem;
          img {
            width: 1.25rem;
            height: 1.25rem;
          }
          p {
            color: $secondary-text;
          }
        }
        .language-dropdown .language-dropdown-items {
          bottom: 10%;
          right: 4%;
        }
      }
    }
  }
}
