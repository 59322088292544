@import "../../../../assets/stylesheets/var";

.popup-modal{
    .pop-up-button{
        margin-right: 12px;
    }
}

.popup-modal.bargraph{
    width: 100%;
    height: 100%;
    background-color: $background;
    border-radius: 10px;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    margin: 0 auto;
    z-index: 99;
    overflow: scroll;
   
    .bargraph-body{
        box-shadow: none;
        border: none;
        border-radius: 0;
    }
   .graph-header{
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: $background;
    padding: 10px 0;
   }
}

.popup-modal.stacked-chart{
    width: 100%;
    height: 100%;
    background-color: $background;
    border-radius: 10px;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    margin: 0 auto;
    z-index: 99;
    overflow-x: scroll;
   
    .persona-body{
        box-shadow: none;
        border: none;
        border-radius: 0;
    }
   
}

.popup-modal.world-map{
    width: 100%;
    height: 100%;
    background-color: $background;
    border-radius: 10px;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    margin: 0 auto;
    z-index: 99;
    overflow-x: scroll;
  
    .graph-header{
        position: sticky;
        top: 0;
        z-index: 2;
        background-color: $background;
        padding: 10px 0;
    }
}

.popup-modal.donut-chart{
    width: 100%;
    height: 100%;
    background-color: $background;
    border-radius: 10px;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    margin: 0 auto;
    z-index: 99;
    overflow: scroll;
    .graph-header{
        position: sticky;
        top: 0;
        z-index: 2;
        background-color: $background;
        padding: 10px 0;
       }
}

.popup-modal.line-chart{
    width: 100%;
    height: 100%;
    background-color: $background;
    border-radius: 10px;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    margin: 0 auto;
    z-index: 99;
    overflow: scroll;
    .graph-header{
        position: sticky;
        top: 0;
        z-index: 2;
        background-color: $background;
        padding: 10px 0;
       }
}