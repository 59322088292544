@import "../../../assets/stylesheets/var";

.main-dark-btn {
  border: 0;
  outline: 0;
  width: 12.5rem;
  height: 3rem;
  border-radius: 0.625rem;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  background: $primary-color;
  color: $background;
}

.main-light-btn {
  border: 2px solid $primary-color;
  outline: 0;
  width: 12.5rem;
  height: 3rem;
  border-radius: 0.625rem;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  background: $background;
  color: $primary-color;
}


.main-dark-btn.disabled{
  background-color: $tert-text;
  color: $secondary-text;
}
