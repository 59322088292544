@import "../../../../assets/stylesheets/var";

.login-section {
  background-color: $background-1;
  .container {
    // max-width: 1296px;
    background-color: $background-1;
  }
  .login-image {
    width: 40.5rem;
    img {
      width: 100%;
      // height:  57.25rem;
      object-fit: cover;
      border-radius: 20px 0 0 20px;
    }
  }
  .login-form {
    width: 40.5rem;
    // height: 57.25rem;
    box-shadow: -16px 4px 15px 0px rgba(0, 0, 0, 0.15);
    background-color: $background;
    padding: 2.5rem 3.12rem 2.5rem 3.12rem;
    border-radius: 0 20px 20px 0;
    .flex-column {
      gap: 22.75rem;
    }
    .heading {
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 700;
      letter-spacing: 0.063rem;
      margin-bottom: 1.88rem;
      color: $primary-text;
    }
    input {
      border: 2px solid $fourth-text;
      margin-top: 0.34rem;
    }
    label {
      color: $primary-text;

      font-size: 1rem;
      font-weight: 600;
    }
    .password-input-wrapper .password-input i {
      bottom: 16px;
      right: 12px;
      color: $fourth-text;
    }
    .email-input {
      margin-bottom: 1.25rem;
    }
    .checkbox {
      p {
        font-size: 1rem;
        font-weight: 500;
        color: $fourth-text;
        cursor: pointer;
      }
      .inner-checkbox {
        align-items: center;
        input {
          width: 1rem;
          height: 1rem;
          cursor: pointer;
          margin-right: 0.62rem;
        }
      }
    }
    button {
      padding: 0.625rem 1.5rem;
      border-radius: 0.375rem;
      background-color: $primary-color;
      color: $background;
      width: 100%;
      outline: 0;
      border: 0;
      font-size: 1rem;
      font-weight: 600;
      margin: 1.88rem 0;
      cursor: pointer;
    }
    .signup-redirect {
      margin-bottom: 2.69rem;
      p {
        font-size: 1rem;
        font-weight: 600;
        color: $primary-text;
        span {
          color: $primary-color-2-shade-2;
          cursor: pointer;
        }
      }
    }
    .login-bottom {
      .bottom-details {
        p {
          color: $secondary-text;
          font-size: 0.75rem;
          font-weight: 400;
          margin-bottom: 0.31rem;
        }
        .underline {
          text-decoration: underline;
        }
        .policies {
          gap: 1.88rem;
        }
      }
      .language-change-login {
        background-color: $background-1;
        padding: 0.75rem 1rem;
        border-radius: 10px;
        .default-icon {
          gap: 1rem;
          img {
            width: 1.25rem;
            height: 1.25rem;
          }
          p {
            color: $secondary-text;
          }
        }
        .language-dropdown .language-dropdown-items {
          margin-top: 1.5rem;
        }
      }
    }
  }
  .login-loader {
    pointer-events: none; 
    cursor: default;
  }
  .login-text-padding{
    padding-right: 5px
  }
}

.dotted-progress-container-login {
  --uib-size: 23px;
  --uib-color: #{$background};
  --uib-speed: 1.3s;
  --uib-dot-size: calc(var(--uib-size) * 0.24);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: var(--uib-size)
}

.dotted-progress-login,
.dotted-progress-container-login::before,
.dotted-progress-container-login::after {
  content: '';
  display: block;
  height: var(--uib-dot-size);
  width: var(--uib-dot-size);
  border-radius: 50%;
  background-color: var(--uib-color);
  transform: scale(0);
  transition: background-color 0.3s ease;
}

.dotted-progress-container-login::before {
  animation: pulse var(--uib-speed) ease-in-out calc(var(--uib-speed) * -0.375)
    infinite;
}

.dotted-progress-login {
  animation: pulse var(--uib-speed) ease-in-out calc(var(--uib-speed) * -0.25)
    infinite both;
}

.dotted-progress-container-login::after {
  animation: pulse var(--uib-speed) ease-in-out calc(var(--uib-speed) * -0.125)
    infinite;
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }
}
