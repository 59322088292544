@import "../../../../../assets/stylesheets/var";

.donut.disabled {
  position: absolute;
  width: 50%;
  padding: 1em 0;
  border-radius: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $background-1;
  opacity: 0.8;
}
