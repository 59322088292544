@font-face {
  font-family: "QuickSand";
  src: local("QuickSand"),
    url("../../data/Quicksand/Quicksand-VariableFont_wght.ttf")
      format("truetype");
  // font-weight: 300; /* Adjust as needed */
  // font-style: normal; /* Adjust as needed */
}

$quicksand: "QuickSand";
$primary-color: #344e41;
$primary-color-shade-1: #5c734f;
$primary-color-shade-2: #85985d;
$primary-color-2: #aebd6b;
$primary-color-2-shade-2: #9eac5f;
$primary-color-3: #c7d38c;
$primary-color-4: #e8f0c5;
$primary-color-5: #bbc87e;
$secondary-color: #aa8b56;
$secondary-color-2: #caae7e;
$secondary-color-3: #e6d1af;
$secondary-color-shade-2: #e5d1af;
$accent-color-1: #ffeacc;
$accent-color-2: #ffd5d5;
$accent-color-3: #c8ffd4;
$status-positive: #82cd47;
$status-negative: #ff6464;
$primary-text: #474747;
$secondary-text: #767676;
$tert-text: #d0d0d0;
$text-shade-2: #565656;
$fourth-text: #b5b5b5;
$background: #ffffff;
$background-1: #f9f9f9;
$negative-dark: #BF0808;
$dark-gray: #888;
$illustration-background: #474747E5;
$graph-axis-color: rgba(71, 71, 71, 1);
$device-pixel-ratio: 3;
$gray-color: #cccccc;
$dark-yellow: #FFC943;
$light-gray : #cdcccc;
$blue: blue;

$theme-graph-colors: (
  "negative": $status-negative,
  "negativeDark": $negative-dark,
  'primaryColor': $primary-color,
  'primaryColor2': $primary-color-2,
  'primaryColor3': $primary-color-3,
  'primaryColorShade2': $primary-color-2-shade-2,
  'primaryColor4': $primary-color-4,
  'primaryColor5': $primary-color-5,
  'secondaryColor3':$secondary-color-3,
  'secondaryColor': $secondary-color,
  'secondaryColor2': $secondary-color-2,
  'secondaryColorShade2':$secondary-color-shade-2,
  "fontQuickSand": $quicksand,
  "background": $background,
  "background1":$background-1,
  "primaryText": $primary-text,
  "secondaryText": $secondary-text,
  "tertiaryText": $tert-text,
  "textShade2": $text-shade-2,
  "fourthText": $fourth-text,
  "graphAxisColor": $graph-axis-color,
  "devicePixelRatio": $device-pixel-ratio,
  "grayColor": $gray-color,
  "primaryColor1Shade1": $primary-color-shade-1,
  "primaryColor1Shade2": $primary-color-shade-2,
  "lightGray": $light-gray
);

:export {
  @each $key, $value in $theme-graph-colors {
    #{unquote($key)}: $value;
  }
}
