@import "../../assets/stylesheets/var";

.persona-heading {
  color: $primary-text;
  font-size: 1.5rem;
}

.padding-bottom-campaign {
  padding-bottom: 15.5rem !important;
}
