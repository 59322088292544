
.load-website-container {
  height: calc(100% - 88px);
  width: 100%;
}

.iframe {
  width: 100%;
  height: 100%;
}

.load-website-button-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.load-website {
  width:100%;
  height: 100%
}