@import "../../../assets/stylesheets/var";

.input-wrapper,
.select-wrapper {
  .common-input {
    border-radius: 10px;
    border: 1.5px solid $tert-text;
    width: 100%;
    padding: 13px;
    margin-top: 10px;
    background: $background;
  }
  .common-input.error {
    border: 1.5px solid $status-negative;
  }

  .add-tag-input-label {
    color: $primary-text;
    font-family: $quicksand;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 130%; /* 20.8px */
    letter-spacing: 0.672px;
  }

  .error-message {
    color: $status-negative;
    text-align: left;
    margin: 7px 0;
    font-family: $quicksand;
    font-weight: 500;
    letter-spacing: 0.8px;
  }
  .add-tag-input-label.disabled {
    background: $background-1;
  }
  .copy-icon {
    position: absolute;
    width: 44px;
    height: 44px;
    right: 0.5rem;
    margin-top: 5px;
    cursor: pointer;
    top: 50%;
    transform: translateY(-50%); /* Vertically centers the icon */
  }

  label {
    color: $secondary-text;
    font-weight: 600;
  }

  .input-container {
    position: relative; /* This allows the icon to be positioned relative to the input */
  }
}

.common-select {
  border-radius: 10px;
  border: 1.5px solid $tert-text;
  height: 50px;
  padding: 13px;
  margin-top: 10px;
  background: $background;
  cursor: pointer;
}
