@import "../../assets/stylesheets/var";

.add-image-model {
  .upload-images {
    font-family: $quicksand;
    font-weight: 700;
    font-size: 24px;
    line-height: 31.2px;
  }
  .close-div{
    position: absolute;
    right: 0;
    top: 2%;
    padding-right: 20px;
    .close-div-img{
        border: none;
        cursor: pointer;
        width: 20px;
        position: relative;
        right: 0;
    }
  } 
  .uploaded-images-name{
    padding-left: 1%;
  padding-top: 1%;
  font-family: $quicksand;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: rgba(208, 208, 208, 1);
  }
}
