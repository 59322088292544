@import "../../../../assets/stylesheets/var";

.billing-subs {
  font-family: $quicksand;
  // min-width: 20vw;
  // min-height: 100px;
  .overflow-x-scroll {
    overflow-x: auto;
  }
  .tile-position {
    position: absolute;
    z-index: 8;
  }
  .downgradedplan-div {
    font-size: 13px;
    .downgradedplan-div-text {
      position: relative;
      top: 20px;
    }
  }
  .loader {
    height: 50vh;
  }

  .coming-soon {
    img {
      width: 400px;
      height: 400px;
    }
    span {
      color: $secondary-text;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 130%;
      letter-spacing: 1.008px;
      margin-bottom: 1rem;
    }
  }

  .select-website {
    border-radius: 10px;
    border: 2px solid $primary-color;
    padding: 10px;
    color: $primary-color;
    font-family: $quicksand;
    cursor: pointer;
    font-weight: 700;
    line-height: 130%; /* 20.8px */
    letter-spacing: 0.672px;
  }

  .select::placeholder {
    background-image: url("../../../../assets/icons/internet.svg");
  }

  .select-website:focus-visible {
    outline: $primary-color auto 2px;
  }

  .MuiLinearProgress-root.MuiLinearProgress-determinate {
    border-radius: 50px;
    height: 20px;
    background-color: $background-1;
  }

  .MuiLinearProgress-bar.MuiLinearProgress-bar1Determinate {
    background-color: $primary-color-3;
  }

  .dataconsumption {
    margin: 20px 0 20px 0;
  }
  .swiper-month {
    width: 20vw;
    margin: 0 auto;

    .swiper-button-prev,
    .swiper-button-next {
      color: $primary-text;
      height: 40px;
      width: 40px;
      border-radius: 100px;
      background: none;
    }
    .swiper-slide {
      text-align: center;
    }

    .swiper-button-next:after,
    .swiper-button-prev:after {
      font-size: 16px;
      font-weight: 700;
    }
  }

  .MuiPaper-root.MuiDialog-paper {
    padding: 15px;
    min-height: 60vh;
  }
  .MuiTypography-root.MuiDialogTitle-root {
    padding: 0 0 16px 0;
    font-family: $quicksand;
    color: $primary-text;
    font-weight: 700;
    font-size: 1.5rem;
  }

  .mt-1 {
    margin-top: 1em;
  }

  .grid {
    gap: 20px;
  }

  .error-input {
    font-size: 12px;
  }
  .label-tag {
    color: $secondary-text;
    font-weight: 500;
  }
  .helper-text {
    color: $secondary-text;
    font-weight: 500;
    letter-spacing: 0.8px;
  }
  .settings-title {
    margin-bottom: 1em;
  }
  .swiper-slide {
    min-width: 240px;
  }

  .close-button {
    margin-left: 5px;
    cursor: pointer;
    width: 20px;
    height: 20px;
  }

  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled {
    display: none;
  }

  .swiper-button-prev,
  .swiper-button-next {
    color: $primary-text;
    height: 40px;
    width: 40px;
    border-radius: 100px;
    background-color: $fourth-text;
  }
  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 14px;
    font-weight: 700;
  }
  .plan-pills {
    cursor: pointer;
    min-width: 240px;
    width: fit-content;
    padding: 10px;
    text-align: center;
    border-radius: 10px;
    background-color: $background-1;
    color: $fourth-text;
  }
  .plan-pills.selected {
    background-color: $primary-color-4;
    color: $primary-color;
  }

  .plan-details {
    color: $secondary-text;
    padding: 0 1em;
    .flex-between {
      margin: 1em 0;
    }

    .plan .flex-between {
      margin: 0;
    }

    .detail-title {
      font-weight: 700;

      span {
        cursor: pointer;
        color: $primary-color;
        text-decoration: underline;
      }
    }
    .bill-details {
      margin: 1em 0;
    }
    .button-group {
      margin: 40px 0;
      display: grid;
      grid-template-columns: auto auto;
      justify-content: center;
      gap: 10px;
    }
  }

  .plan-list {
    .list-title {
      color: $primary-text;
      font-weight: 700;
      line-height: 130%;
      letter-spacing: 0.672px;

      .toggle-button {
        font-weight: normal;
        padding: 5px;
        border-radius: 50px;
        background-color: $primary-color-4;
        display: grid;
        grid-template-columns: auto auto;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        span {
          padding: 5px 10px;
        }
        span.selected {
          color: $primary-color-4;
          background-color: $primary-color;
          border-radius: 50px;
        }
      }
    }

    .plan {
      color: $primary-text;
      cursor: pointer;
      width: 100%;
      height: 9vw;
      border: 1px solid $tert-text;
      border-radius: 10px;
      padding: 20px 15px;
      margin: 1em 0;
      align-items: flex-start;

      position: relative;
      transition: all 0.5s;
      overflow: hidden;

      .rectangle {
        width: 200px;
        height: 200px;
        background-color: $primary-color-4;
        position: absolute;
        transform-origin: top left;
        transform: scaleX(1) scaleY(1);
        transition: width 0.4s;
      }

      .diagonal-1 {
        top: 0;
        left: 0;
        transform: rotate(48.976deg);
        border-radius: 20px;
      }

      .diagonal-2 {
        top: -150px;
        right: -100px;
        transform: rotate(59.023deg);
        border-radius: 50px;
      }

      .left,
      .right {
        height: 6vw;
      }
      .flex-between {
        width: 95%;
      }

      .left.flex-column {
        justify-content: space-around;
        flex-shrink: 0;
      }

      .right.flex-column-end {
      }
      .change-plan {
        display: none;
      }

      .amount {
        color: $primary-text;
        text-align: right;
        font-family: $quicksand;
        font-size: 1.2rem;
        font-weight: 700;
        line-height: 130%; /* 31.2px */
        letter-spacing: 1.008px;
        white-space: nowrap;
      }
      .plan-name {
        font-size: 1.2rem;
      }
      .datapoints {
        font-size: 1rem;
        a {
          font-size: inherit;
          text-decoration: underline;
        }
      }
    }

    .plan.converge {
      .change-plan {
        display: inline-block;
        font-size: 1rem;
        font-family: $quicksand;
        font-weight: 500;
        width: fit-content;
        padding: 0 10px !important;
        margin-top: 10px;
      }
      .diagonal-1 {
        width: 50%;
        transform: rotate(0deg);
        border-radius: 0;
        flex-shrink: 0;
      }
      .diagonal-2 {
        width: 50%;
        top: 0;
        right: 0;
        transform: rotate(0deg);
        border-radius: 0;
        flex-shrink: 0;
      }
    }

    .plan.current {
      color: $background;
      border: 2px solid $primary-color-3;
      background: linear-gradient(92deg, $primary-color 4.64%, #698e7c 115.44%);

      .amount {
        color: $background;
        white-space: nowrap;
      }
      span {
        color: black;
        padding: 5px 10px;
        border-radius: 20px;
        background-color: $primary-color-4;
      }
    }
  }

  .display-billing,
  .display-all-address {
    .bill-details.display {
      border: 2px solid $primary-color;
      background-color: $background-1;
    }
    .bill-details.list {
      cursor: pointer;
      border: 1px solid $background;
      input[type="radio"] {
        margin-right: 1em;
        appearance: none;
        cursor: pointer;
        width: 20px;
        height: 20px;
        border: 3px solid $primary-text;
        border-radius: 50%;
      }
      input[type="radio"]:before {
        content: "";
        display: block;
        width: 70%;
        height: 70%;
        margin: 15% auto;
      }
      input[type="radio"]:checked::before {
        background-color: $primary-text;
        border-radius: 50%;
      }
    }

    .bill-details.list:hover {
      border-color: $primary-color;
    }

    .bill-details.list.selected {
      background-color: $background-1;
    }

    .bill-details.list.selected:hover {
      border: 1px solid $background-1;
    }

    .bill-details {
      .flex {
        justify-content: space-between;
      }
      padding: 10px 15px;
      border-radius: 10px;
      min-height: 118px;

      color: $primary-text;
      font-weight: 500;
      .name {
        font-family: $quicksand;
        font-weight: 800;
      }
      .vat {
        font-weight: 700;
      }

      .address {
        font-family: $quicksand;
        height: 3em;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      .address.add-margin {
        margin-left: 2.5em;
      }

      img {
        cursor: pointer;
        width: 45px;
        height: 45px;
      }
    }
    hr {
      margin: 5px 0;
      background-color: $background-1;
      height: 2px;
      border: none;
      border-radius: 10px;
    }
  }

  .display-all-address.address-list {
    min-height: inherit;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .display-all-address {
    .add-new {
      font-weight: 800;
      line-height: 130%; /* 20.8px */
      letter-spacing: 0.672px;
      cursor: pointer;
      border: 0;
      padding: 5px;
      background-color: $background;
      color: $primary-color;
    }
  }

  .current-usage {
    .grid {
      gap: 10px;
    }
    .usage-insights {
      margin-top: 2rem;
      .usage-values {
        text-align: right;
      }
    }

    hr {
      margin-top: 10px;
      background-color: $tert-text;
      height: 2px;
      border: none;
      border-radius: 10px;
    }
  }
}

.expirydate {
  position: absolute;
  right: 2px;
  top: 40px;
  .expirydate-text {
    font-family: $quicksand;
    font-weight: 500;
    font-size: 16px;
  }
}

/* For tablets */
@media (max-width: 1024px) {
  .expirydate {
    position: absolute;
    right: 2px;
    bottom: 2px;
  }

  .billing-subs {
    .plan-list {
      .plan {
        height: 100px;
        .datapoints {
          margin-top: 20px;
        }
      }
    }
  }
}
