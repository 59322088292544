@import "../../../../assets/stylesheets/var";

.viewLabelData {
  .label-detail {
    align-items: flex-end;
    h3 {
      font-size: 1.5rem;
      font-weight: 500;
      color: $secondary-text;
      text-transform: capitalize;
    }
    .action-div {
      gap: 1rem;
      align-self: flex-end;
      .action-button {
        align-items: center;
        cursor: pointer;
        gap: 7px;
        p {
          font-size: 1.2rem;
          color: $secondary-text;
        }
        i {
          font-size: 1.2rem;
          color: $secondary-text;
        }
      }
    }
  }
  .date-range {
    width: 22rem;
    margin: 1.5rem auto 0.7rem auto;
    .flex {
      gap: 0.8rem;
    }
    p {
      background-color: $primary-color-4;
      color: $secondary-text;
      padding: 0.6rem 0.8rem;
      border-radius: 1rem;
    }
  }
  .calendar-view {
    margin-top: 1.5rem;
    .rdrDateRangePickerWrapper {
      .rdrDefinedRangesWrapper {
        display: none;
      }
      .rdrDateRangeWrapper {
        .rdrMonthAndYearWrapper {
          .rdrMonthAndYearPickers {
            color: $secondary-text;
          }
        }
      }
    }
    .button-combo {
      position: absolute;
      width: 76%;
      bottom: 1.8rem;
      left: 29rem;
    }
  }
}
