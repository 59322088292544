/* NotificationBar.css */
@import "../../assets/stylesheets/var";

.notification-bar {
    position: absolute;
    top: 70px;
    right: 0px;
    width: 500px; /* Set the desired width */
   
    background-color: #fff;
    z-index: 999;
    // border: 1px solid #ccc;
    box-shadow: -12px 4px 19px 0px rgba(0, 0, 0, 0.10);

    overflow: hidden;
    transition: height 0.3s ease-in-out;
    border-radius: 10px;
  }

 .notification-heading{
  padding: 15px 10px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.10);
  .header{
    font-family: $quicksand;
    color: $text-shade-2;
    font-size: 20px;
    font-weight: 500;
    line-height: 130%; /* 26px */
    letter-spacing: 0.84px
  }
 }
  
  .notification-bar.open {
    // height: 20vw; /* Set the desired height when open */
   .closeNoti{
    height: 18px;
    width: 18px;
    cursor: pointer;
   }

   .mark-read{
    border: none;
    background: no-repeat;
    text-decoration: underline;
    margin-right: 8px;
    font-family: $quicksand;
    font-weight: 600;
    color: $text-shade-2;
    cursor: pointer;
  }
  }
  
  
  .notification-list {
    overflow-y: scroll; /* Enable vertical scrolling */
    min-height: 72vh;
    height: 72vh;
    // max-height: 240px; /* Set the maximum height for the notification list */
    
    .no-notifications{
      display: flex;
      align-items: center; /* Center vertically */
      justify-content: center;
      font-family: $quicksand;
      font-weight: 500;
      color: $secondary-text;
      margin-top: 50%;
    }
  }
  
  .notification-item {
    padding: 20px 10px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    cursor: pointer;

    
   
    img{
      height: 36px;
      width: 36px;
      margin-right: 15px;
    }

    .notificationText{
      margin-bottom: 10px;
      color: $primary-text;
      font-family: $quicksand;
      font-weight: 600;

    }

    .notificationBody{
      color: $secondary-text;
      font-family: $quicksand;
      font-weight: 500;
      letter-spacing: 0.8px;
      .link{
        font-family: $quicksand;
        font-weight: 700;
        letter-spacing: 0.8px;
        color: $primary-color;       
        text-decoration-line: underline;
      }
    }

    

    
  }
  
  .notification-item:last-child {
    border-bottom: none;
  }

  .notification-bottom{
    background: #9f9f9f;
    opacity: 0.1;
    margin:0;
  }
  
  .notification-time{
   
   font-family: $quicksand;
   color: $secondary-text;
   font-weight: 500;
   line-height: 130%; /* 20.8px */
   letter-spacing: 0.672px;
  }

  .notification-item.read{
    background: $background-1;
  }

  .notification-item:hover{
    background: $primary-color-4;
  }

  .clearAllButton{
    // position: fixed;
    // bottom: 0;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.10);
    width: 100%;
    border: none;
    background: $background;
    padding: 15px 5px;
    font-family: $quicksand;
    color: $text-shade-2;
    text-decoration: underline;
    font-size: 20px;
    font-weight: 500;
    line-height: 130%; /* 26px */
    letter-spacing: 0.84px;

  }