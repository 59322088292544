@import "../../assets/stylesheets/var";

.tagMain {
  margin-bottom: 2rem;
}
.upload-bottom{
  padding-bottom: "24px"
}
.upload-left{
  padding-left: "7%"
}
.left-button,
.right-button {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.left-button {
  left: "20px";
}
.right-button {
  right: 0;
}
.image-name {
  font-family: $quicksand;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}
.image-date {
  font-family: $quicksand;
  font-weight: 500;
  font-size: 14px;
  line-height: 17.5px;
}
.image-container:hover .delete-button {
  display: block;
}
.uploadImages {
  padding-top: 2%;
  position: relative;
  
  .data-table,
  .add-tag-form-box {
    width: 70%;
    background-color: $background;
    border-radius: 10px;
    padding: 10px;

    .half-column-grid {
      display: grid;
      grid-template-columns: 49% 49%;
      gap: 20px;
    }

    .add-tag-form-grid {
      grid-template-columns: auto;
      gap: 20px;
      padding: 2% 0;
    }
  }
  .tag-manager-heading {
    font-family: $quicksand;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; 
    letter-spacing: 1.008px;
    color: $primary-text;
  }
}

.search-filter{
  position: relative;
  bottom: 8px;
  margin-right: 12px
}

.search-bar{
  margin-right: "5%";
}

.image-button {
  border: none;
  cursor: pointer;
  width: 20px;
  position: relative;
  right: 10px;
  top: 15px
}

.sort-icon{
  border: none;
  cursor: pointer;
  width: 29px;
  height: 29px;
  position: relative;
  top: 10px
}

// Tablet media queries

/* Medium devices (tablets, 768px and up) */
@media (max-width: 1200px) {
  .uploadImages {
    width: 1200px;
  }
  .datatable-width{
    width: 1540px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 768px) {
  .uploadImages {
    width: 1000px;
  }
  .datatable-width{
    width: 1270px;
  }
  .image-button {
    position: relative;
    right: 10px;
    top: 7px
  }
  .delete-multiple{
    position: relative;
    bottom: 7px;
  }
  .sort-icon{
    border: none;
    cursor: pointer;
    width: 29px;
    height: 29px;
    position: relative;
    top: 2px
  }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 576px) {
  .uploadImages {
    width: 10rem;
  }
}

// Tablet media queries ends


// Mobile media queries

/* Extra Small Devices, Phones */
@media (max-width: 320px) {
  /* Your styles for extra small devices */
  .uploadImages {
    width: 50rem;
  }
  .search-filter{
    bottom: 4px;
  }
  .search-bar{
    bottom: 4px;
  }
}

/* Small Devices, Phones in Landscape */
@media (min-width: 320px) and (max-width: 480px) {
  /* Your styles for small devices in landscape mode */
  .uploadImages {
    width: 120rem;
  }
  .search-filter{
    bottom: 4px;
  }
  .search-bar{
    position: relative;
    top: 4px;
  }
  .image-button {
    position: relative;
    right: 10px;
    top: 10px
  }
  .delete-icon{
    position: relative;
    top: 10px;
  }
  .delete-multiple{
    position: relative;
    bottom: 4px;
  }
  .datatable-width{
    width: 1200px;
  }
  .sort-icon{
    position: relative;
    top: 4px
  }
}

/* Small Devices, Phones in Portrait */
@media (min-width: 481px) and (max-width: 600px) {
  /* Your styles for small devices in portrait mode */
  .uploadImages {
    width: 10rem;
  }
  .search-filter{
    bottom: 4px;
  }
  .search-bar{
    bottom: 4px;
  }
}

// Mobile media queries ends

