@import "../../assets/stylesheets/var";

.dropdown {
  .dropdown-placeholder {
    font-family: $quicksand;
    font-weight: 700;
    line-height: 130%;
    letter-spacing: 0.672px;
    width: 15.625rem;
    p {
      // text-transform: capitalize;
      color: $background;
      font-size: 1rem;
    }

    i {
      color: $background;
      font-weight: 600;
      cursor: pointer;
    }
    cursor: pointer;
  }
  .active-page {
    width: 15.625rem;
    background-color: $primary-color-4;
    border-radius: 8px;
    padding: 0.5rem 1rem;
    font-weight: 700;
    p {
      color: $primary-color;
      // text-transform: capitalize;
      font-size: 1rem;
    }
    i {
      color: $primary-color;
      font-weight: 600;
      cursor: pointer;
    }
    cursor: pointer;
  }
  .dropdown-menu {
    width: 15.625rem;
    background-color: $background-1;
    border-radius: 10px;
    // border: 1px solid $tert-text;
    margin-top: 7px;
    position: absolute;
    z-index: 100;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    a {
      display: block;
    }

    .dropdown-item {
      padding: 8px;
      .page-name {
        color: $secondary-text;
        // text-transform: capitalize;
        cursor: pointer;
        font-size: 1rem;
      }
    }

    .dropdown-item:hover {
      background-color: $primary-color-4;
      border-radius: 10px;
    }
    a:nth-last-child(1) {
      border: 0;
    }
  }
}
