.flex {
  display: flex;
}

.flex-row{
  flex-direction: row;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-pointer{
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.flex-justify-pointer{
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.flex-end{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-center-align{
  display: flex;
  align-items: center;
}
.flex-vertical {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.flex-column{
  display: flex;
  flex-direction: column;
}

.flex-column-height{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%
}

.flex-center-height{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%
}

.flex-column-end{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}
.flex-column-center{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.flex-space{
  display: flex;
  justify-content: space-between;
}

.flex-justify-center{
  display: flex;
  justify-content: center;
}

.flex-row-between{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.flex-column-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.flex-vertical-between{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.flex-justify-end{
  display: flex;
  justify-content: flex-end;
}