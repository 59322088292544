@import "../../assets/stylesheets/var";

.opened-image-model-images {
  .fixed-element {
    cursor: pointer;
    position: fixed;
    top: 50%;
    left: 18%;
    transform: translateY(-50%);
    z-index: 999;
  }
  .bin-icon-image-model {
    border: none;
    cursor: pointer;
    width: 60px;
    height: 60px;
    position: relative;
    right: 10px;
    top: 14px;
  }
  .fixed-element-right {
    position: fixed;
    top: 50%;
    right: 17%;
    transform: translateY(-50%);
    z-index: 999;
    .next-image {
      border: none;
      cursor: pointer;
      width: 60px;
      height: 60px;
      position: relative;
      right: 10px;
      top: 14px;
    }
  } 
  .close-icon-div{
    position: absolute;
  right: 0px;
  padding-right: 20px;
  padding-top: 20px;
  .close-icon{
    border: none;
  cursor: pointer;
  width: 20px;
  position: relative;
  right: 0px;
  }
  } 
  .image-attributes{
    max-width: 100%;
  max-height: 100%;
  width: 500px;
  height: 500px;
  object-fit: contain;
  }
}
