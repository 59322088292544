@import "../../../assets/stylesheets/var";

.confirm-password-input-wrapper {
  label {
    margin-bottom: 10px;
    color: $primary-text;
  }
  .confirm-password-input {
    position: relative;
    input {
      width: 100%;
      margin-top: 10px;
      padding: 13px;
      border: 1.5px solid $tert-text;
      border-radius: 8px;
    }
    .error{
      border: 1.5px solid $status-negative;
  }

    i {
      position: absolute;
      bottom: 10px;
      right: 5px;
    }
  }
  .error-message {
    color: $status-negative;
    text-align: left;
    margin: 5px 0;
  }
  .add-tag-input-label {
    color:$primary-text;
    font-family: $quicksand;
    font-size: .875rem;
    font-weight: 500;
    line-height: 130%; /* 20.8px */
    letter-spacing: 0.672px;
    }

}
