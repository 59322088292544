@import "../../../../../assets/stylesheets/var";

.tagManagerButton {
  margin-left: 10px;
}

.tooltip {
  align-items: center;
}

.label-tag {
  font-family: $quicksand;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.8px;
  color: $secondary-text;
}

.tool-tip{
  font-family: $quicksand;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.8px;
  color: $background
}

.add-tag-input-label {
  color: $primary-text;
  font-family: $quicksand;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 130%; /* 20.8px */
  letter-spacing: 0.672px;
}

.add-tag-title,
.custom-checkbox-label {
  font-family: $quicksand;
  color: $primary-text;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 20.8px */
  letter-spacing: 0.672px;
  margin-bottom: 5px;
}

.add-tag-subtitle {
  font-family: $quicksand;
  color: $secondary-text;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.8px;
}

.input-select {
  margin-left: 10px;
  margin-right: 10px;
  outline: 0;
  border-width: 0 0 1px;
  border-color: black;
  background: none;
}

.customTag {
  border-radius: 10px;
  border: 1px solid $tert-text;
  background: $background-1;
  padding: 8px;
  margin-left: 10px;
  display: flex;
  align-items: center;
}

.selectButton {
  background: url("../../../../../assets/icons/tickButton.svg") no-repeat;
  cursor: pointer;
}

.selectButton,
.closeButton {
  background-size: 20px !important;
  cursor: pointer;
  width: 20px;
  height: 20px;
  border: none;
  border-radius: 10px;
}

.closeButton {
  background: url("../../../../../assets/icons/closeButton.svg") no-repeat;
}

.editButton,
.deleteButton,
.close-button {
  border: none;
  background: none;
  cursor: pointer;
}

.input-view {
  margin-top: 10px;
}

.custom-checkbox {
  display: none;
  cursor: pointer;
}

.custom-checkbox-label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkmark {
  cursor: pointer;
  width: 20px;
  height: 20px;
  border: 2px solid $primary-color;
  border-radius: 4px;
  margin-right: 8px;
  position: relative;
}

.custom-checkbox:checked + .custom-checkbox-label .checkmark::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;
  background-color: $primary-color;
}

.half-column-grid-trigger-selection {
  display: grid;
  grid-template-columns: calc(50% - 10px) calc(50% - 10px);
  gap: 20px;
}

.error-input {
  color: $status-negative;
  font-family: $quicksand;
  font-style: normal;
  margin: 10px 0 10px 0;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.8px;
}

.info-text-price-location {
  color: $secondary-text;
  padding-top: 5px;
  font-size: 1rem;
}

.info-text-iframe {
  color: $secondary-text;
  margin-left: auto;
  padding-top: 5px;
  font-size: 1rem;
}

.popUpInfoTagLocation {
  color: $secondary-text;
  font-size: 0.875rem;
  position: fixed;
  background-color: white;
  width: 80%;
  z-index: 100;
  height: 80%;
  font-weight: 500;
  letter-spacing: 0.672px;
  margin-top: 5px;
  top: 50%;
  left: 50%;
  border-radius: 10px;
  transform: translate(-50%, -50%);
}