@import "../../../../../../assets/stylesheets/var";

.bargraph-body {
  position: relative;
  padding: 10px;

  .question {
    font-size: 1rem;
    font-family: $quicksand;
    font-weight: 500;
    padding: 10px 0px;
    margin-left: 10px;
    color: $primary-text;
  }

  .questionsContainer{
    display: flex;
    flex-grow: 1;
    width: 50%;
    justify-content: flex-end;
    margin-top: 20px;
  }
  
  .content {
    flex-grow: 1;
    width: 50%;
    margin: 10px 20px 0px 0px;
  }
  
  .content:last-child {
    margin-right: 0; 
  }

  .disabled {
    pointer-events: none; 
    opacity: 0.2;
  }

  @media (max-width: 1024px) {
    .content {
      width: calc(50% - 20px);
      margin: 10px 20px 0px 0px;
      margin-bottom: 20px;
    }
  
    .content:last-child {
      margin-right: 0;
    }
  }
}
