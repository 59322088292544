@import "../../../../../assets/stylesheets/var";
.validate {
  box-shadow: 0px 4px 10px 0px #0000001a;
  border-radius: 8px;
  padding: 1.2rem;
  width: 60%;
  height: 550px;

  h3 {
    color: $primary-text;
    margin-bottom: 2.4rem;
    font-size: 1.5rem;
    font-weight: 500;
  }
  .heading-details {
    color: $primary-text;
    margin-bottom: 1.7rem;
  }
  form {
    label {
      color: $primary-text;
      display: block;
      margin-bottom: 1rem;
      font-size: 14px;
    }
    input {
      width: 10%;
      border: 1px solid $tert-text;
      outline: 0;
      padding: 1rem 0.7rem;
      border-radius: 8px;
      margin-right: 1rem;
      text-align: center;
    }
    .error-message {
      color: $status-negative;
      text-align: left;
      margin: 7px 0 0 0;
    }
    .buttons {
      margin-top: 1.4rem;
      .send-again {
        border: 2px solid $primary-color;
        outline: 0;
        padding: 0.75rem;
        width: 43%;
        background-color: $background;
        font-size: 1.2rem;
        color: $primary-color;
        font-weight: 500;
        border-radius: 10px;
        cursor: pointer;
      }
      .confirm {
        border: 0;
        outline: 0;
        padding: 0.75rem;
        width: 43%;
        background-color: $primary-color;
        font-size: 1.2rem;
        color: $background;
        font-weight: 500;
        border-radius: 10px;
        margin-left: 1.2rem;
        cursor: pointer;
      }
    }
  }
  .info {
    margin-top: 1.8rem;
    .notice {
      text-transform: uppercase;
      color: red;
      margin-bottom: 4px;
    }
    .notice-details {
      color: $secondary-text;
      font-style: italic;
    }
  }
}
