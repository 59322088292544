@import "../../../../../assets/stylesheets/var";

.website-dropdown {
  position: relative;
  display: inline-block;
 
  .label {
    width: 13.75rem;
    cursor: pointer;
    .details {
      align-items: center;
      img {
        width: 1.03963rem;
        height: 1.03863rem;
      }
      p {
        margin-left: 0.5rem;
        color: $background;
        font-family: $quicksand;
        font-weight: 700;
        line-height: 130%; 
        letter-spacing: 0.672px;
      }
    }
    img {
      width: 1.25rem;
      height: 1.25rem;
      cursor: pointer;
    }
  }
  // .label.active-page{
  //   background-color: $primary-color-4;
  //   border-radius: 8px;
  //   padding: 0.5rem 1rem;
  //   p{
  //     color: $primary-color;
  //   }
  // }

  .website-dropdown-items {
    position: absolute;
    left: 0;
    top: 2rem;
    width: 20rem;
    max-height: 300px; /* Adjust the height as needed */
    overflow-y: auto;
    background-color: $background-1;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    // border: 1px solid $tert-text;
    border-radius: 10px;
    z-index: 100;

    .dropdown-item {
      padding: 0.625rem 0.9375rem;
      cursor: pointer;
      transition: background-color 0.3s ease;
      border-radius: inherit;

      &:hover {
        background-color: $primary-color-4;
        color: $primary-color;
      }

      img {
        width: 1.5rem;
        height: 1.5rem;
      }

      p {
        font-size: 1rem;
        color:$secondary-text;
        margin: 0;
      }
    }
  }
}
