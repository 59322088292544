@import "../../assets/stylesheets/var";

.drag-drop {
  .main-div {
    cursor: pointer;
    margin-top: 2%;
    padding-top: 4%;
    padding-bottom: 4%;
    padding-left: 4%;
    background-color: rgba(249, 249, 249, 1);
  }
  .drag-drop-text {
    text-align: center;
    font-family: $quicksand;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
  }
  .select-file {
    text-align: center;
    font-family: $quicksand;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-decoration: underline;
    color: rgba(86, 86, 86, 1);
  }
  .upload-images-div{
    justify-content: center;
    align-items: center;
    padding-top: 2%;
      .upload-images-length {
        font-family: $quicksand;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: rgba(158, 172, 95, 1);
      }
  } 
  .image-preview{
    padding-top: 2%;
    font-family: $quicksand;
    font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: rgba(118, 118, 118, 1);
  }
}
