@import "../../../../../assets/stylesheets/var";

.profile-settings {
  // padding: 10px;

  i {
    display: flex;
    justify-content: flex-end;
    margin-right: 4px;
    margin-top: 4px;
    cursor: pointer;
    font-size: 1.3rem;
    color: $tert-text;
  }

  .avatar-container {
    position: relative;

    .file-input {
      position: absolute;
      top: 128px;
      left: 380px;
      border: none;
      z-index: 1;
      background: none;
      cursor: pointer;
    }

    .avatarClass {
      border: 1px solid $primary-color;
    }
  }

  .buttonEdit {
    border: none;
    background: none;
    cursor: pointer;
  }

  .log-out {
    border-radius: 10px;
    border: none;
    margin-bottom: 10px;
    background: $primary-color-4;
    color: $primary-text;
    cursor: pointer;
  }

  .log-out,
  .delete-account {
    font-family: $quicksand;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 20.8px */
    letter-spacing: 0.672px;
    cursor: pointer;
  }

  .delete-account {
    border-radius: 10px;
    border: none;
    background: $accent-color-2;
    color: $status-negative;
  }

  .log-out,
  .delete-account {
    img {
      width: 40px;
      height: 40px;
    }
  }

  .label-tag {
    color: $secondary-text;
  }

  .buttonSpacing {
    margin-left: 10px;
  }

  .editButtonTop {
    margin-top: 10px;
  }
  .reset-pwd {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
}
