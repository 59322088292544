@import "../../../../assets/stylesheets/var";

.delete-custom-range {
  padding: 2rem;
  background: #fff;
  border-radius: 1.25rem;
  text-align: center;
  position: absolute;
  right: 19%;
  top: 23%;
  img {
    width: 40%;
  }
  p {
    color: $text-shade-2;
    font-size: 1rem;
    margin: 1.2rem 0 2rem 0;
  }
}
