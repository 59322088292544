@import "../../../assets/stylesheets/var";

.filter-pill-parent{
    flex-wrap: wrap;
    
    .filter{
        color: $fourth-text;
        height: 19px;
    }
    .selected-name{
        color: $primary-text;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 90%;
        height: 19px;
    }

    .delete-button{
        background-color: inherit;
        border: none;
        display: flex;
        margin-left: 12px;
        padding: 0;
        cursor: pointer;
    }

    .filter-pill{
        padding: 10px;
        background: $background;
        border: 1.5px solid $fourth-text;
        border-radius: 10px;
        margin-right: 20px;
        // margin-bottom: 10px;
        display: flex;
        align-items: center;
        max-width: 300px;    
    }

    .popup-filter-list{
        position: absolute;
        top: 53px;
        left: 433px;
        padding: 15px 10px;
        max-width: 400px;
        .selected-name{
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 90%;
        }

        .clear-button{
            background-color: inherit;
            border: none;
            cursor: pointer;
        }
    }
}