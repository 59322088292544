@import "../../../../../assets/stylesheets/var";

.sidebar {
  box-shadow: 0px 4px 10px 0px #0000001a;
  border-radius: 8px;
  padding: 1.5rem 2rem;
  width: 38%;
  height: 550px;
  .column-flex {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 15rem;
    .sidebar-nav {
      .flex {
        align-items: center;
        margin-bottom: 1.5rem;
        img {
          height: 25px;
          width: 25px;
          margin-right: 1.2rem;
        }
      }
      .check {
        color: $primary-color;
      }
      .active {
        color: $primary-color-2;
      }
      .passive {
        color: $fourth-text;
      }
    }
    .sidebar-footer {
      p {
        color: $secondary-text;
      }
      p:nth-child(1) {
        margin-bottom: 1rem;
      }
    }
  }
}
