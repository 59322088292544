@import "../../../../../../assets/stylesheets/var";

.inputs-width {
  width: 100%;
  .input-field-width {
    width: 90%;
  }
  .input-margin {
    padding-top: 3%;
  }
  .ai-settings-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ai-settings-icon {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
}

.setting-dropdown-container {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  .setting-dropdown-div {
    margin-right: 20px;
  }
}
