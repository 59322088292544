@import "../../../../../../assets/stylesheets/var";


.dotted-progress-container {
    --uib-size: 35px;
    --uib-color: #9EAC5F;
    --uib-speed: 1.3s;
    --uib-dot-size: calc(var(--uib-size) * 0.24);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: var(--uib-size);
  }

  .connection-text{
    padding-left: 10px;
    padding-right: 10px;
    font-family: $quicksand;
    color: $secondary-text;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 5%;
  }
 
  .dotted-progress,
  .dotted-progress-container::before,
  .dotted-progress-container::after {
    content: '';
    display: block;
    height: var(--uib-dot-size);
    width: var(--uib-dot-size);
    border-radius: 50%;
    background-color: var(--uib-color);
    transform: scale(0);
    transition: background-color 0.3s ease;
  }
 
  .dotted-progress-container::before {
    animation: pulse var(--uib-speed) ease-in-out calc(var(--uib-speed) * -0.375)
      infinite;
  }
 
  .dotted-progress {
    animation: pulse var(--uib-speed) ease-in-out calc(var(--uib-speed) * -0.25)
      infinite both;
  }
 
  .dotted-progress-container::after {
    animation: pulse var(--uib-speed) ease-in-out calc(var(--uib-speed) * -0.125)
      infinite;
  }
 
  @keyframes pulse {
    0%,
    100% {
      transform: scale(0);
    }
 
    50% {
      transform: scale(1);
    }
  }