@import "../../../../../assets/stylesheets/var";

.hamburger-dropdown {
  position: relative;
  display: inline-block;

  .hamburger-icon {
    width: 2.5rem;
    height: 2.5rem;
    cursor: pointer;
  }

  .dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    width: 15.625rem;
    background-color: #fff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    z-index: 1000;
    overflow-x: visible !important; /* Override overflow-x */

    .dropdown-item {
      padding: 0.625rem 0.9375rem;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #f7f7f7;
      }

      img {
        width: 1.5rem;
        height: 1.5rem;
      }

      p {
        font-size: 1rem;
        color: $secondary-text;
        margin: 0;
      }
    }
    .language {
      .language-dropdown .language-dropdown-items {
        right: 102%;
        top: 24%;
      }
    }
  }
}
