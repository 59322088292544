@import "../../../../../../assets/stylesheets/var";

.no-image-found {
  .close-img-div {
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
    padding-top: 20px;
    .close-img {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
  .not-found-div {
    display: flex;
    justify-content: center;
    align-items: center;
    .not-found-img {
      width: 270px;
      height: 240px;
    }
  }
  .warning-div {
    display: flex;
    justify-content: center;
    align-items: center;
    .warning-text {
      font-family: $quicksand;
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;
      letter-spacing: 1%;
      color: $text-shade-2;
      padding-bottom: 20px;
    }
    .no-images-text {
      font-family: $quicksand;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 5%;
      color: $text-shade-2;
    }
  } 

  .cancel-proceed-buttons{
    margin: 40px;
    .cancel-right{
        padding-right: 20px;
    }
  }
}
