@import "../../../../assets/stylesheets/var";

.add-custom-calendar-range {
  display: flex;
  flex-direction: column;
  .rdrDateRangePickerWrapper {
    // box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    .rdrDefinedRangesWrapper {
      display: none;
      border-right: 0;
    }
    .rdrMonthPicker {
      background: $primary-color-4;
      border-radius: 4px;
      color: $primary-color;
    }
    .rdrYearPicker {
      @extend .rdrMonthPicker;
      padding-left: 1rem;
      padding-right: 0.5rem;
    }
    .rdrMonthName {
      color: $primary-color;
    }
  }
  .custom-label-input {
    width: 70%;
    margin: 0 auto;
    margin-bottom: 1.2rem;
    padding: 0.75rem 1rem;
    border-radius: 8px;
    outline: 0;
    border: 0;
    border: 1px solid $primary-color;
  }
  .custom-label-input::placeholder {
    color: $primary-color;
  }
  .button-combo {
    position: absolute;
    width: 76%;
    bottom: 1.8rem;
    left: 29rem;
  }
}
