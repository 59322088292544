@import "../../../.././../assets/stylesheets/var";

.categories {
    .enable-all{
        color: $primary-text;
        font-family: $quicksand;
        font-size: 16px;
        font-weight: 700;
        line-height: 130%; /* 20.8px */
        letter-spacing: 0.672px;
        margin: 10px;
        cursor: pointer;
        border: none;
        background: none;
    }
    .categories-header{
        color: $tert-text;
        font-family: $quicksand;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px; /* 20.8px */
        letter-spacing: 0.672px;
    }
    .categories-name{
        color: $primary-text;
        font-family: $quicksand;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px; /* 20.8px */
        letter-spacing: 0.672px;
    }
    .margin15{
        margin: 15px 10px
    }

    .disableEnableAll {
        color: $gray-color !important;
        cursor: auto;
    }

    .categories-container{
        max-height: 650px;
        overflow-y: auto;
    }
}