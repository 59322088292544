@import "../../assets/stylesheets/var";

.MuiDataGrid-cell {
  font-family: $quicksand;
  color: $primary-text;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.info{
  font-weight: 700;
  font-size: 16px;
  line-height: 20.7px;
  padding-left: 1.3%;
}

.search-position{
  position: relative;
  bottom: 8px;
  margin-right: 12px;
}

.right-align {
  // position: absolute;
  // // top: 0;
  // left: 200;
  // margin: 10px;
}

.MuiDataGrid-row:hover {
  cursor: pointer;
}

.MuiIconButton-root:hover {
  background-color: white !important;
}
.MuiDataGrid-columnHeaderTitle {
  font-family: $quicksand;
  color: $tert-text;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.MuiTablePagination-selectLabel {
  font-family: $quicksand, Arial, sans-serif;
}

.MuiDataGrid-main {
  min-height: 350px;
}

.no-data-found-text {
  display: flex;
  justify-content: center;
  font-family: $quicksand;
  color: $tert-text;
  font-size: 20px;
  font-weight: 600;
  line-height: 130%;
  margin: 100px 0px;
}