@import "../../assets/stylesheets/var";


footer {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  min-width: 1500px;
  background-color: #f5f5f5;
  padding: 30px 0;
  margin-top: 200px;
  z-index: 900;
  small {
    color: $secondary-text;
  }
  .footer-width {
    display: flex;
    color: $secondary-text;
  }
}


