@import "../../../../assets/stylesheets/var";

.insight-box {
  background: $primary-text;
  border-radius: 10px;
  padding: 10px 33.5px;
  .compare-insight-value {
    color: $secondary-color-shade-2;
    line-height: 46.8px;
    white-space: nowrap;
  }

  .line-break {
    height: 100%;
    border-radius: 20px;
    background: $fourth-text;
    width: 2px;
  }

  .insight-value {
    color: $primary-color-2-shade-2;
    white-space: nowrap;
  }

  .insight-value,
  .compare-insight-value {
    font-family: $quicksand;
    letter-spacing: 1.512px;
    font-style: normal;
    font-weight: 700;
    font-size: 2.25rem;
    margin-bottom: 0.5rem;
    white-space: nowrap;
  }

  .insite-value-size {
    font-size: 1.7rem;
  }

  .insight-title {
    font-family: $quicksand;
    color: $background-1;
    letter-spacing: 0.672px;
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 130%;
  }

  .insight-title-size {
    font-size: 0.85rem;
  }

  .general-insights {
    padding: 14px 0;
  }
}
