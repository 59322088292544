@import "../../../../assets/stylesheets/var";

.calendar-button-combo {
  gap: 1rem;
  button {
    outline: 0;
    width: 12.5rem;
    height: 3rem;
    border-radius: 0.625rem;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
  }
  button:nth-child(1) {
    border: 2px solid $primary-color;
    background: $background;
    color: $primary-color;
  }
  button:nth-child(2) {
    border: 0;
    background: $primary-color;
    color: $background;
  }
}
