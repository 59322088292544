@import "../../../../../assets/stylesheets/var";
.header-onboarding {
  background-color: $primary-color;
  .onboarding-container {
    max-width: 1650px;
    margin: 0 auto;
    padding: 1rem;
    .logo {
      img {
        height: 2.7rem;
        width: 2.7rem;
      }
    }
    .nav-element {
      align-items: center;
      li {
        margin-right: 1.3rem;
        cursor: pointer;
        img {
          height: 1.5rem;
          width: 1.5rem;
        }
      }
      li:nth-child(5) {
        margin-right: 0;
      }
      .language-dropdown .language-dropdown-items {
        right: 2rem;
      }
    }
  }
}
