@import "../../../../assets/stylesheets/var";


.data-export-heading {
    font-family: $quicksand;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 31.2px */
    letter-spacing: 1.008px;
    color: $primary-text;
    padding-top: 2%;
    padding-bottom: 2%;
  }

.bargraph-body {
    position: relative;
    padding: 10px;

    .data-export {
        display: flex;
        justify-content:space-between;
        align-items: center;
    }

    .data-export-image {
        max-width: 40%;
        height: auto;
        margin: 0 10px;
    }

    .data-export-header{
        color: $primary-text;
        font-family: $quicksand;
        font-weight: 700;
        line-height: 130%; /* 20.8px */
        letter-spacing: 0.672px;
        margin-top: 10px;
       }

    .data-export-content{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        width: 60%;
    }
    .buttons {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        margin-top: 20px;
    }
    .MuiInputBase-root{
        border: 2px solid #ccc;
        border-radius: 10px !important;
    }
}