@import "../../../../assets/stylesheets/var";

.standard-calendar {
  width: 914px;
  // height: 690px;
  background: $background;
  // margin-bottom: 200px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 0.7rem 1rem;
  position: absolute;
  right: 0;
  // right: 15%;
  .close-icon {
    width: 1.2rem;
    height: 1.2rem;
    position: absolute;
    right: 1rem;
    cursor: pointer;
  }
  .calendar-options {
    margin-top: 1.9rem;
    h3 {
      color: $secondary-text;
      font-size: 1rem;
      font-weight: 500;
    }
    .calendar-toggle {
      p:nth-child(1) {
        margin-right: 1.3rem;
      }
      p {
        font-size: 0.85rem;
        color: $secondary-text;
        font-weight: 400;
        cursor: pointer;
      }
      .active {
        font-weight: 500;
        color: $primary-color;
      }
    }
  }
  .label-and-standard-range {
    margin-top: 0.5rem;
    justify-content: space-between;
    .label-list-parent {
      width: 21%;
      display: flex;
      align-self: flex-start;
      flex-direction: column;
      gap: 2.5rem;
      .label-list {
        button {
          border: 0;
          outline: 0;
          background-color: $background;
          color: $secondary-text;
          width: 11.57rem;
          border-radius: 4px;
          padding: 0.82rem 0.8rem;
          font-size: 0.9rem;
          text-align: left;
          cursor: pointer;
          margin-bottom: 0.4rem;
          &.selected {
            background-color: $primary-color;
            color: $background;
          }
          &.selected:hover {
            background-color: $primary-color;
            color: $background;
          }
        }
        button:hover {
          background-color: $background-1;
          color: $secondary-text;
        }
      }
    }
    .range-selection {
      width: 76%;
      //position: relative;
      .rdrDateRangePickerWrapper {
        border-radius: 10px;
        .rdrDefinedRangesWrapper {
          display: none;
        }
        .rdrMonthPicker {
          background: $primary-color-4;
          border-radius: 4px;
          color: $primary-color;
        }
        .rdrYearPicker {
          @extend .rdrMonthPicker;
          padding-left: 1rem;
          padding-right: 0.5rem;
        }
        .rdrMonthName {
          color: $primary-color;
        }
      }
      .compare-text {
        display: none;
      }
      .button-combo {
        position: relative;
        // width: 76%;
        // bottom: 9rem;
        // left: 29rem;
        margin-right: 30px;
        margin-top: 10px;
      }
      .toggle-on-range-selection {
        p {
          display: block;
          position: absolute;
          top: 7.8rem;
          left: 28rem;
          color: $secondary-text;
        }
        .rdrDateRangePickerWrapper {
          .rdrDateDisplayWrapper {
            .rdrDateDisplay:nth-child(1) {
              padding-bottom: 1.8rem;
            }
          }
        }
      }
    }
  }
}

.rdrDateDisplayItem input {
  font-family: $quicksand, Arial, sans-serif;
}

.rdrMonthAndYearPickers select {
  font-family: $quicksand, Arial, sans-serif;
}

standard-calendar
  .label-and-standard-range
  .range-selection
  .rdrDateRangePickerWrapper
  .rdrMonthPicker,
.standard-calendar
  .label-and-standard-range
  .range-selection
  .rdrDateRangePickerWrapper
  .rdrYearPicker {
  font-family: $quicksand, Arial, sans-serif;
}

.toggle-buttom {
  position: relative;
  bottom: 1rem
}
