@import "../../../../../../assets/stylesheets/var";

.close-icon-img {
  border: none;
  cursor: pointer;
  width: 20px;
  position: relative;
  right: 0px;
}
.buttons-div {
  padding-bottom: 20px;
  .cancel-button-padding-right {
    padding-right: 2%;
  }
}
.ai-settings-div {
  min-width: 950px;
  .ai-settings {
    .body-container {
      display: flex;
      flex-direction: "column";
      // background-color: wheat;
    }
    .active-tab {
      border-bottom: 5px solid $primary-color;
      background-color: $primary-color-4;
    }
    .tabs {
      margin-right: 20px;
      cursor: pointer;
      padding: 20px;
    }
    .tab-container {
      display: flex;
      flex-direction: row;
    }
    .body-left-container {
      display: flex;
      flex-direction: column;
      flex: 0.6;
    }
    .body-right-container {
      display: flex;
      flex-direction: column;
      flex: 0.4;
      align-items: center;
      justify-content: center;
      min-width: 320px;
    }
    .generated-designs-margins {
      margin-right: 2%;
    }
    .button-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 20px;
    }
    .label-name {
      font-family: $quicksand;
      font-weight: 700;
      font-size: 16px;
      line-height: 20.7px;
      letter-spacing: "7%";
      color: $secondary-text;
      padding-top: 2%;
      padding-left: 2%;
    }
    .input-field-width-ai-model {
      width: 100%;
      padding-left: 2%;
    }
    .options {
      background-color: $background-1;
      width: 45%;
      height: 450px;
      margin-top: 2%;
      // margin-bottom: 4%;
      .ai-settings-options-div {
        height: 44px;
        cursor: pointer;
        background-color: transparent;
        &.selected {
          background-color: $primary-color-4;
        }
        .ai-settings-options {
          font-family: $quicksand;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          color: $primary-color;
          padding-left: 7%;
        }
      }
    }
    .ai-settings-values-div {
      margin-top: 1.1%;
      width: 100%;
      .ai-settings-values {
        font-family: $quicksand;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: $primary-color;
        margin-left: 4%;
        margin-bottom: 0%;
      }
      .horizontal-break-div {
        margin-bottom: 4.5%;
      }
      .horizontal-break {
        border: 1px solid $background-1;
      }
    }

    /* Base styles for radio button */
    input[type="radio"] {
      appearance: none;
      cursor: pointer;
      width: 20px;
      height: 20px;
      border: 2px solid $fourth-text; // Customize border color
      border-radius: 50%; // Radio button is circular
      margin-right: 4%;
      position: relative; // Allows absolute positioning of inner circle
    }

    /* Style for the inner circle when the radio button is not selected */
    input[type="radio"]::before {
      content: "";
      display: block;
      width: 60%;
      height: 60%;
      border-radius: 50%; // Inner circle should be circular
      background-color: transparent; // Default background is transparent
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); // Center the inner circle
    }

    /* Style for the inner circle when the radio button is selected */
    input[type="radio"]:checked::before {
      background-color: $graph-axis-color; // Customize the color when selected
    }
  }
}

.rcp-root {
  --rcp-background-color: #121212;
  --rcp-field-input-color: #ffffff;
  --rcp-field-input-border-color: #242424;
  --rcp-field-label-color: #808080;
}
.rcp {
  display: flex;
  flex-direction: column;
  background-color: var(--rcp-background-color);
  border-radius: 10px;
}
.rcp-body {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 10px;
}
.rcp-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.rcp-interactive {
  width: 100%;
  height: 100%;
  user-select: none;
  touch-action: none;
}
.rcp-interactive[aria-disabled="true"] {
  cursor: unset;
  pointer-events: none;
}
.rcp-saturation {
  cursor: all-scroll;
  width: 100%;
  position: relative;
  background-image: linear-gradient(to bottom, transparent, black),
    linear-gradient(to right, white, transparent);
  border-radius: 10px 10px 0 0;
}
.rcp-saturation-cursor {
  position: absolute;
  width: 20px;
  height: 20px;
  border: 2px solid white;
  border-radius: 100%;
  box-shadow: 0 0 15px #00000026;
  transform: translate(-10px, -10px);
}
.rcp-hue {
  cursor: ew-resize;
  position: relative;
  width: 100%;
  height: 12px;
  background-image: linear-gradient(
    to right,
    red,
    #ff0,
    #0f0,
    #0ff,
    #00f,
    #f0f,
    red
  );
  border-radius: 10px;
}
.rcp-hue-cursor {
  position: absolute;
  width: 20px;
  height: 20px;
  border: 2px solid white;
  border-radius: 100%;
  box-shadow: 0 0 15px #00000026;
  transform: translate(-10px, -4px);
}
.rcp-alpha {
  cursor: ew-resize;
  position: relative;
  width: 100%;
  height: 12px;
  border-radius: 10px;
}
.rcp-alpha-cursor {
  position: absolute;
  width: 20px;
  height: 20px;
  border: 2px solid white;
  border-radius: 100%;
  box-shadow: 0 0 15px #00000026;
  transform: translate(-10px, -4px);
}
.rcp-fields {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.rcp-fields-floor {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 10px;
}
.rcp-field {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.rcp-field-input {
  width: 100%;
  font-family: inherit;
  font-size: 14px;
  text-align: center;
  color: var(--rcp-field-input-color);
  background-color: transparent;
  border: 2px solid var(--rcp-field-input-border-color);
  border-radius: 5px;
  outline: none;
  padding: 5px 0;
}
.rcp-field-input:read-only {
  opacity: 0.8;
}
.rcp-field-label {
  font-size: 14px;
  text-align: center;
  color: var(--rcp-field-label-color);
}
