@import "../../../../assets/stylesheets/var";

.signup-section {
  background-color: $background-1;
  .container {
    // max-width: 1296px;
    background-color: $background-1;
  }
  .signup-image {
    width: 40.5rem;
    img {
      width: 100%;
      height: 57.25rem;
      object-fit: cover;
      border-radius: 20px 0 0 20px;
    }
  }
  .signup-form {
    width: 40.5rem;
    height: 57.25rem;
    box-shadow: -16px 4px 15px 0px rgba(0, 0, 0, 0.15);
    background-color: $background;
    padding: 2.5rem 3.13rem 6.13rem 3.13rem;
    border-radius: 0 20px 20px 0;
    .heading {
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 700;
      letter-spacing: 0.063rem;
      margin-bottom: 1.88rem;
      color: $primary-text;
    }
    input {
      border: 2px solid $fourth-text;
      margin-top: 0.34rem;
    }
    label {
      color: $primary-text;
      font-size: 1rem;
      font-weight: 600;
    }
    .password-input-wrapper .password-input i {
      bottom: 16px;
      right: 12px;
      color: $fourth-text;
    }
    .confirm-password-input-wrapper .confirm-password-input i {
      bottom: 16px;
      right: 12px;
      color: $fourth-text;
    }
    .seperator {
      margin-bottom: 1.25rem;
    }
    .note {
      color: $primary-text;
      font-size: 0.8rem;
      margin-top: 3px;
    }
    .name-input {
      gap: 1.25rem;
    }
    .error-message {
      color: $status-negative;
      text-align: left;
      margin: 7px 0;
      font-family: $quicksand;
      font-weight: 500;
      letter-spacing: 0.8px;
    }
    .terms-and-condition {
      textarea {
        border-radius: 0.625rem;
        border: 2px solid $fourth-text;
        background: #fff;
        width: 100%;
        height: 5.9375rem;
        color: $secondary-text;
        font-size: 1rem;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.05rem;
        margin-top: 0.34rem;
        padding: 0 0.63rem;
        overflow-y: auto;
        resize: none;
      }
      textarea::-webkit-scrollbar {
        -webkit-appearance: none;
      }
      textarea::-webkit-scrollbar:vertical {
        width: 11px;
      }

      textarea::-webkit-scrollbar:horizontal {
        height: 11px;
      }

      textarea::-webkit-scrollbar-thumb {
        border-radius: 8px;
        border: 2px solid white; /* should match background, can't be transparent */
        background-color: rgba(0, 0, 0, 0.5);
      }
    }
    .accept-terms-and-condition {
      .flex {
        align-items: center;
      }
      input {
        width: 1.25rem;
        height: 1.25rem;
      }
      .title {
        color: $primary-text;
        font-size: 1rem;
        font-weight: 600;

        margin-left: 0.62rem;
      }
    }
    .accept-privacy-policy {
      .flex {
        align-items: center;
      }
      input {
        width: 1.25rem;
        height: 1.25rem;
      }
      .title {
        color: $primary-text;
        font-size: 1rem;
        font-weight: 600;
        margin-left: 0.62rem;
        a {
          color: $primary-color-2-shade-2;
        }
      }
    }
    button {
      padding: 0.625rem 1.5rem;
      border-radius: 0.375rem;
      background-color: $primary-color;
      color: $background;
      width: 100%;
      outline: 0;
      border: 0;
      font-size: 1rem;
      font-weight: 600;
      margin: 1.88rem 0;
      cursor: pointer;
    }
  }

  .login-redirect {
    margin-bottom: 1.75rem;
    p {
      font-size: 1rem;
      font-weight: 600;
      color: $primary-text;
      span {
        color: $primary-color-2-shade-2;
        cursor: pointer;
      }
    }
  }
  .signup-bottom {
    .bottom-details {
      p {
        color: $secondary-text;
        font-size: 0.75rem;
        font-weight: 400;
        margin-bottom: 0.31rem;
      }
      .policies {
        gap: 1.88rem;
      }
    }
    .language-change-signup {
      background-color: $background-1;
      padding: 0.75rem 1rem;
      border-radius: 10px;
      .default-icon {
        gap: 1rem;
        img {
          width: 1.25rem;
          height: 1.25rem;
        }
        p {
          color: $secondary-text;
        }
      }
      .language-dropdown .language-dropdown-items {
        margin-top: 1.5rem;
      }
    }
  }
  .register-loader {
    pointer-events: none; 
    cursor: default;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .signup-section {
    .signup-image {
      img {
        height: 63.25rem;
      }
    }
    .signup-form {
      height: 63.25rem;
    }
  }
}

.dotted-progress-container-register {
  --uib-size: 23px;
  --uib-color: #{$background};
  --uib-speed: 1.3s;
  --uib-dot-size: calc(var(--uib-size) * 0.24);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: var(--uib-size)
}

.dotted-progress-register,
.dotted-progress-container-register::before,
.dotted-progress-container-register::after {
  content: '';
  display: block;
  height: var(--uib-dot-size);
  width: var(--uib-dot-size);
  border-radius: 50%;
  background-color: var(--uib-color);
  transform: scale(0);
  transition: background-color 0.3s ease;
}

.dotted-progress-container-register::before {
  animation: pulse var(--uib-speed) ease-in-out calc(var(--uib-speed) * -0.375)
    infinite;
}

.dotted-progress-register {
  animation: pulse var(--uib-speed) ease-in-out calc(var(--uib-speed) * -0.25)
    infinite both;
}

.dotted-progress-container-register::after {
  animation: pulse var(--uib-speed) ease-in-out calc(var(--uib-speed) * -0.125)
    infinite;
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }
}
