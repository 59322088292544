@import "../../../assets/stylesheets/var";

.MuiOutlinedInput-root{
    border-radius: 10px !important;
    height: 50px;
    border: 1.5px solid $tert-text;
    margin-top: 10px;
}

.MuiOutlinedInput-notchedOutline{
    border-style: none !important;
}
.MuiButtonBase-root.MuiMenuItem-root.Mui-selected{
    background-color: $primary-color-4;
    .menuItemList{
        color: $primary-color;
    }
}

.MuiButtonBase-root.MuiMenuItem-root{
    border-radius: 10px;
}

.MuiButtonBase-root.MuiMenuItem-root:hover{
    background-color: $background-1;
    .menuItemList{
        color: $primary-color;
    }

}

.MuiButtonBase-root.MuiMenuItem-root.Mui-selected:hover{
    background-color: $primary-color-4;
}

.menuItemList{
    color: $fourth-text;
    font-weight: 500;
    line-height: 130%; /* 20.8px */
    letter-spacing: 0.672px;
}
.error-message {
    color: $status-negative;
    font-family: $quicksand;
    text-align: left;
    margin: 7px 0;
    font-weight: 500;
  }



/* multiSelect.scss */

